// @flow
import { TOGGLE_MODAL } from './actionTypes'
import type { Action } from './types'

export default function toggleModal(open: boolean): Action {
  return {
    type: TOGGLE_MODAL,
    payload: open,
  }
}
