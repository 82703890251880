// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import Button from 'components/Button'
import Countdown from 'components/Countdown'
import truncate from 'utils/truncate'

import styles from './style'
import type { Props } from './types'

export default function Box({ item = {}, ...props }: Props): React$Element<'div'> {
  return (
    <div
      className={css(
        styles.box,
        !!props.isBig && styles['box--big'],
        !props.isBig && styles['box--flexColumn'],
        props.color === '#ffcc33' && styles['box--yellow']
      )}
      style={{ backgroundColor: props.color }}
    >
      {props.isBig && (
        <div className={css(styles.image)} style={{ backgroundImage: `url(${props.img})` }} />
      )}
      <div>
        <h5 className={css(styles.box__preline)}>{props.preline}</h5>
        <h3 className={css(!props.color && styles.box__headline)}>{props.headline}</h3>
        <p
          dangerouslySetInnerHTML={{ __html: props.isBig ? props.text : truncate(props.text, 80) }}
        />
      </div>

      <div>
        {props.countdown && <Countdown data={props.countdown} />}
        {props.countdown && <div style={{ marginTop: 20 }} />}

        {props.dokumentStattBeitragVerlinken && (
          <Button to={props.dokument}>{props.buttonText}</Button>
        )}
        {props.globallink && (
          <Button
            to={props.globallink}
            style={props.dokumentStattBeitragVerlinken ? { marginLeft: 5 } : { marginRight: 5 }}
          >
            {props.globalerLinkText}
          </Button>
        )}

        {props.newlink && (
          <Button
            external={props.newlink.target !== ''}
            to={props.newlink.url}
            style={props.dokumentStattBeitragVerlinken ? { marginLeft: 5 } : { marginRight: 5 }}
          >
            {props.newlink.title}
          </Button>
        )}

        {(props.isBig && !props.showLink) ||
          (!props.dokumentStattBeitragVerlinken && item.postName && (
            <Button to={item.postType === 'post' ? `/blog/${item.postName}/` : `${item.postName}/`}>
              {props.buttonText}
            </Button>
          ))}
      </div>
    </div>
  )
}
