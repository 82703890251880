// @flow
import { StyleSheet } from 'aphrodite/no-important'

const rotate = {
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
}

export default StyleSheet.create({
  ring: {
    display: 'block',
    position: 'relative',
    margin: '0 auto',
    width: 24,
    height: 24,
  },

  'ring--white': {},

  'ring--x2': {
    width: 48,
    height: 48,
  },

  ring__inner: {
    boxSizing: 'border-box',
    display: 'block',
    position: 'absolute',
    width: 18,
    height: 18,
    margin: 2,
    border: '2px solid #003b68',
    borderRadius: '50%',
    animationName: [rotate],
    animationDuration: '1.2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(0.5, 0, 0.5, 1)',
    borderColor: '#003b68 transparent transparent transparent',
    opacity: 1,

    ':nth-child(1)': {
      animationDelay: '-0.45s',
    },

    ':nth-child(2)': {
      animationDelay: '-0.3s',
    },

    ':nth-child(3)': {
      animationDelay: '-0.15s',
    },
  },

  'ring__inner--white': {
    borderColor: '#fff transparent transparent transparent',
  },

  'ring__inner--x2': {
    width: 36,
    height: 36,
    borderWidth: 4,
    margin: 4,
  },
})
