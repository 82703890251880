// @flow
import { SELECT_VIDEO } from './actionTypes'
import type { Action } from './types'

export default function selectVideo(index: string): Action {
  return {
    type: SELECT_VIDEO,
    payload: index,
  }
}
