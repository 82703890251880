// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { rgba } from 'polished'
import { colors } from 'constants'

export default StyleSheet.create({
  main: {
    borderBottom: '1px solid #d5dfe5',
  },
  mainTitle: {
    paddingLeft: 24,
    paddingRight: 24,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 18,
    color: colors.darkFont,
    cursor: 'pointer',
    outline: 'none',
    transition: 'background-color 90ms linear',
    ':hover': {
      backgroundColor: rgba('#d5dfe5', 0.3),
    },
  },
  mainBody: {
    paddingLeft: 24,
    paddingRight: 24,
    overflow: 'hidden',
    maxHeight: 0,
    // transition: 'all 300ms linear',
  },
  mainArrow: {
    marginLeft: 10,
    width: 18,
    height: 10,
    transition: 'transform 300ms ease',
    transform: 'rotate(180deg)',
  },
  mainArrowActive: {
    transform: 'rotate(0)',
  },
  mainBodyActive: {
    borderTop: '1px solid #d5dfe5',
    paddingBottom: 24,
    paddingTop: 24,
    maxHeight: 'auto',
  },
})
