import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Helmet from "react-helmet";

import ScrollToTop from "react-router-scroll-top";
import { hot } from "react-hot-loader";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";

import { closeSearch } from "modules/ui/search/actions";
import validateUserToken from "modules/auth/validate/actions";
import {
  selectValidateStatus,
  selectValidateLoaded,
} from "modules/auth/validate/selectors";
import { Header } from "components";
import callApi from "utils/callApi";
import Preloader from "components/Preloader";

import Cookies from "js-cookie";

import ReactGA from "react-ga4";
import config from "app/config";

import Home from "../Home";
import About from "../About";
import Shop from "../Shop";
import Search from "../Search";
import Page from "../Page";
import Datenschutz from "../Datenschutz";
import NotFound from "../NotFound";
import Videos from "../Videos";
import Ansprechpartner from "../Ansprechpartner";
import AnsprechpartnerPartner from "../AnsprechpartnerPartner";
import Events from "../Events";
import Cart from "../Cart";
import Auth from "../Auth";
import IframeDemo from "../IframeDemo";

ReactGA.initialize("G-M55FP0S01F", {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123,
  },
});

class Root extends Component {
  static defaultProps = { isValid: false, loaded: false };

  state = { isValid: false, loaded: false, cipher: false, userLogin: "" };

  componentDidMount = async (): Promise<*> => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
    if (Cookies.get("token")) {
      await this.props.validateUserToken();
    } else {
      this.requestLogin();
    }

    await this.loadCipher();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isValid !== this.props.isValid) {
      this.setState({ isValid: nextProps.isValid });
      this.loadCipher();
    }

    if (nextProps.loaded !== this.props.loaded) {
      this.setState({ loaded: nextProps.loaded });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged = () => this.props.closeSearch();

  loadCipher = async (): Promise<*> => {
    try {
      const response = await callApi("/cipher/v1/personal", { secure: true });
      const { cipher } = response;

      this.setState({ userLogin: response.userLogin });

      this.setState({ cipher });
    } catch (e) {
      console.error("Something went wrong getting the cipher", e);

      this.setState({ cipher: false });
    }
  };

  requestLogin = () => {
    this.setState({ loaded: true, isValid: false });
  };

  render(): React$Node {
    const { loaded, isValid } = this.state;

    if (!loaded) {
      return <Preloader />;
    }

    if (!isValid && this.props.location.pathname !== "/login") {
      return (
        <Redirect to={`/login?redirectTo=${this.props.location.pathname}`} />
      );
    }

    return (
      <div>
        <Helmet
          defaultTitle={config("projectName").toString()}
          titleTemplate={`${config("projectName").toString()} — %s`}
        >
          <html key="html" lang="en" amp />
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href={require("../../assets/favicon/apple-icon-57x57.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href={require("../../assets/favicon/apple-icon-60x60.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href={require("../../assets/favicon/apple-icon-72x72.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href={require("../../assets/favicon/apple-icon-76x76.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href={require("../../assets/favicon/apple-icon-114x114.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href={require("../../assets/favicon/apple-icon-120x120.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href={require("../../assets/favicon/apple-icon-144x144.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href={require("../../assets/favicon/apple-icon-152x152.png")}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={require("../../assets/favicon/apple-icon-180x180.png")}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href={require("../../assets/favicon/android-icon-192x192.png")}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={require("../../assets/favicon/favicon-32x32.png")}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href={require("../../assets/favicon/favicon-96x96.png")}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={require("../../assets/favicon/favicon-16x16.png")}
          />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta
            name="msapplication-TileImage"
            content={require("../../assets/favicon/ms-icon-144x144.png")}
          />
          <meta name="theme-color" content="#ffffff" />
          {config("defaultMetaTags.enabled") && [
            <meta
              key="keywords"
              name="keywords"
              content={config("defaultMetaTags.keywords")}
            />,
            <meta
              key="title"
              name="title"
              content={config("defaultMetaTags.title")}
            />,
            <meta
              key="robots"
              name="robots"
              content={config("defaultMetaTags.robots")}
            />,
            <meta
              key="description"
              name="description"
              content={config("defaultMetaTags.description")}
            />,
            config("defaultMetaTags.openGraphEnabled") && [
              <meta
                key="ogTitle"
                property="og:title"
                content={config("defaultMetaTags.title")}
              />,
              <meta
                key="ogType"
                property="og:type"
                content={config("defaultMetaTags.type")}
              />,
              <meta
                key="ogDescription"
                property="og:description"
                content={config("defaultMetaTags.description")}
              />,
              <meta
                key="ogSiteName"
                property="og:site_name"
                content={config("defaultMetaTags.siteName")}
              />,
            ],
          ]}
        </Helmet>
        <Header cipher={this.state.cipher} isValid={isValid} />

        <Switch>
          <Route path="/404" component={NotFound} />
          <Route path="/login" component={Auth} />
          {isValid && (
            <Route
              exact
              path="/"
              component={({ history, location, match }) => (
                <Home
                  history={history}
                  location={location}
                  match={match}
                  cipher={this.state.cipher}
                  userLogin={this.state.userLogin}
                />
              )}
            />
          )}
          {isValid && <Route exact path="/shop" component={Shop} />}
          {isValid && <Route exact path="/search" component={Search} />}
          {isValid && (
            <Route exact path="/iframe-demo" component={IframeDemo} />
          )}
          {isValid && (
            <Route exact path="/ansprechpartner" component={Ansprechpartner} />
          )}
          {isValid && (
            <Route
              exact
              path="/ansprechpartner/partner"
              component={AnsprechpartnerPartner}
            />
          )}
          {isValid && <Route exact path="/warenkorb" component={Cart} />}
          {isValid && (
            <Route exact path="/datenschutz" component={Datenschutz} />
          )}
          {/* {isValid && <Route exact path="/infokompakt" component={InfoKompakt} />} */}
          {isValid && <Route exact path="/about" component={About} />}
          {isValid && <Route exact path="/videos" component={Videos} />}
          {isValid && (
            <Route exact path="/veranstaltungen" component={Events} />
          )}
          {isValid && (
            <Route
              component={() => <Page userLogin={this.state.userLogin} />}
            />
          )}

          {!isValid && <Route path="/" component={Auth} />}
        </Switch>

        <ScrollToTop />
      </div>
    );
  }
}

export default compose(
  hot(module),
  withRouter,
  connect(
    (state) => ({
      isValid: selectValidateStatus(state),
      loaded: selectValidateLoaded(state),
    }),
    { validateUserToken, closeSearch }
  )
)(Root);
