// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import type { Props } from './types'
import styles from './style'

export default function UpDownUi(props: Props): React$Element<'div'> {
  return (
    <div className={css(styles.container)}>
      <a
        onClick={props.onUpClick}
        role="button"
        tabIndex={-1}
        className={css(styles.svg__container)}
      >
        <svg className={css(styles.svg)} viewBox="0 0 8 4" width="12" height="6">
          <path
            d="M1 3l3-2 3 2"
            stroke="#2771C6"
            strokeWidth="1.5"
            fill="none"
            fillRule="evenodd"
          />
        </svg>
      </a>
      <a
        onClick={props.onDownClick}
        role="button"
        tabIndex={-1}
        className={css(styles.svg__container)}
      >
        <svg className={css(styles.svg)} viewBox="0 0 8 4" width="12" height="6">
          <path
            d="M1 1l3 2 3-2"
            stroke="#2771C6"
            strokeWidth="1.5"
            fill="none"
            fillRule="evenodd"
          />
        </svg>
      </a>
    </div>
  )
}
