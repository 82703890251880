import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { css } from "aphrodite/no-important";
import unescape from "utils/unescape";
import find from "lodash/find";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

import Cookies from "js-cookie";

import Spin from "components/Spin";
import { fetchPage, clearPage } from "modules/page/actions";
import { loadSingleContact } from "modules/contact/actions";
import { selectPage, selectLoaded } from "modules/page/selectors";
import { selectSingleContact } from "modules/contact/selectors";
import { selectMenuData } from "modules/menu/selectors";

import SideBarContact from "components/Sidebar/Contact";
import Overflow from "components/Overflow";
import IssuesTable from "components/IssuesTable";
import { Provider as FlexibleContentElements } from "components/FCE/Provider";

import {
  Footer,
  Masthead,
  FontControl,
  Content,
  DocumentsTable,
} from "components";
import { absprungLink, absprungTrigger } from "./absprunglinks";

import functionality from "./index.functionality";
import styles from "./style";

import "./style.css";
import checkUrl from "../../utils/checkUrl";

class Page extends Component {
  content = React.createRef();

  state = {
    menuID: -1,
    sortValue: "date,DESC",
    absprungElement: [],
  };

  async componentDidMount() {
    this.props.clearPage();
    await this.handlePage(this.props.location);
    this.handleThirdMenu(this.props.menu.items);
    this.handleAccordions();

    absprungTrigger.forEach((item) => {
      const el = document.getElementById(item) || false;
      this.setState({ absprungElement: [...this.state.absprungElement, el] });
    });

    setTimeout(() => {
      this.state.absprungElement.forEach((item, index) => {
        if (item) {
          item.addEventListener("click", (e) =>
            this.handleRedirect(e, absprungLink[index])
          );
        }
      });
    }, 500);
  }

  handleAccordions = () => {
    const accordion_head = document.getElementsByClassName("accordions-head");
    this.setState({ accordion_head });

    const accordion_content = document.getElementsByClassName(
      "accordion-content"
    );
    this.setState({ accordion_content });
  };

  handleRedirect = (e, val) => {
    e.preventDefault();

    fetch(
      `https://portal.ovb.de/wp-json/serverside/v2/check/partner_id=${val}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        if (data) {
          const text = atob(data.Content);
          const parser = new DOMParser();
          const decoded = parser.parseFromString(text, "text/xml");

          if (decoded.getElementsByTagName("errorText")[0]) {
            const x = decoded.getElementsByTagName("errorText")[0];
            const y = x.childNodes[0];
            const z = y.nodeValue;
            alert(z);
          } else if (decoded.getElementsByTagName("message")[0]) {
            const x = decoded.getElementsByTagName("message")[0];
            const y = x.childNodes[0];
            const z = y.nodeValue;
            alert(z);
          } else {
            const x = decoded.getElementsByTagName("url")[0];
            const y = x.childNodes[0];
            const z = y.nodeValue;
            window.open(z, "_blank") || window.location.replace(z);
          }
        } else {
          alert(
            "Es tut uns leid. Leider besteht ein technisches Problem. Versuchen Sie es später erneut oder wenden Sie sich an Ihren Ansprechpartner."
          );
        }
      })
      .catch((err) => console.error(err));
  };

  async componentWillReceiveProps(nextProps): React$Node {
    if (nextProps.location !== this.props.location) {
      await this.handlePage(nextProps.location);
      this.handleThirdMenu(this.props.menu.items);
    }

    this.handleThirdMenu(nextProps.menu.items);
  }

  componentWillUnmount() {
    this.content.current.removeEventListener("click", this.onClick, true);
    if (this.state.element) {
      this.state.element.removeEventListener("click", this.handleClick);
    }
  }

  onClick = functionality;

  handleSideBarContact = (acfs) => {
    acfs.forEach((partner) => {
      this.props.loadSingleContact(partner.iD);
    });
  };

  handleThirdMenu = (items) => {
    const { location } = this.props;

    if (!items) return;

    const mItem = find(items, (item) => {
      if (item.url.replace(checkUrl(), "") === location.pathname) {
        return item;
      }
    });

    if (typeof mItem !== "undefined") {
      let currentId = mItem.iD.toString();
      let currentItems = filter(
        items,
        (item) => item.menuItemParent === currentId
      );

      if (!currentItems.length) {
        currentId = mItem.menuItemParent;
        currentItems = filter(
          items,
          (item) => item.menuItemParent === mItem.menuItemParent
        );
      }

      this.setState({ menuID: currentId });
    }
  };

  async handlePage(location): React$Node {
    const { pathname = "" } = location;
    const slug = pathname.split("/");
    const realSlug = slug[slug.length - 2];

    try {
      const { response } = await this.props.fetchPage(realSlug);

      if (this.content && this.content.current) {
        this.content.current.addEventListener("click", this.onClick, true);
      }

      const acfExist = response[0].acf.ansprechpartner;
      if (acfExist) {
        this.handleSideBarContact(acfExist);
      }
    } catch (e) {
      this.props.history.replace("/404");
    }
  }

  onSortChange = (val) => {
    this.setState({ sortValue: val });
  };

  render(): React$Element<"div"> {
    let contentClassName = "col-sm-12";
    let data = this.props.data[0];

    if (this.state.accordion_head) {
      for (let index = 0; index < this.state.accordion_head.length; index++) {
        const element = this.state.accordion_head[index];
        element.addEventListener("click", (e) => {
          if (!element.classList.contains("active")) {
            this.state.accordion_content[index].classList.remove("active");
            this.state.accordion_head[index].classList.remove("active");
            return null;
          }
          for (let index = 0; index < this.state.accordion_head.length; ) {
            this.state.accordion_content[index].classList.remove("active");
            this.state.accordion_head[index].classList.remove("active");
            index++;
          }
          this.state.accordion_content[index].classList.add("active");
          element.classList.add("active");
        });
      }
    }

    if (!this.props.loaded || typeof data === "undefined") {
      data = {
        title: { rendered: "Loading ..." },
        content: { rendered: "Loading ..." },
        breadcrumb: [""],
        acf: { dokumenteAnzeigen: false },
      };
    }

    let menuItems = [];

    if (this.props.menu.items) {
      menuItems = this.props.menu.items.filter(
        (item) => item.menuItemParent === this.state.menuID.toString()
      );
    }

    if (
      menuItems.length ||
      (data.acf.dokumenteAnzeigen && data.acf.dokumente) ||
      !!this.props.singleData.length
    ) {
      contentClassName = "col-sm-9";
    }

    if (
      (menuItems.length ||
        (data.acf.dokumenteAnzeigen && data.acf.dokumente)) &&
      !!this.props.singleData.length
    ) {
      contentClassName = "col-sm-9";
    }

    let documents = Array.isArray(data.acf.dokumente)
      ? [...data.acf.dokumente].reverse()
      : [];
    const [sort, direction] = this.state.sortValue.split(",");

    documents = orderBy(documents, [sort], [direction.toLowerCase()]);

    const disableWhenFCEExists =
      this.props.data[0] && this.props.data[0].acf.flexibleContentElemente;

    return (
      <div ref={this.content} className={css(styles.main)}>
        {!disableWhenFCEExists && <FontControl />}
        <Helmet title={unescape(data.title.rendered)} />
        {disableWhenFCEExists && (
          <div className="row">
            <FlexibleContentElements {...this.props} />
          </div>
        )}
        {!disableWhenFCEExists && (
          <Masthead
            color={data.pageColor}
            loading={!this.props.loaded}
            preline={[...data.breadcrumb]
              .reverse()
              .map(unescape)
              .join(" · ")}
            breadcrumb={data.breadcrumb}
            headline={data.title.rendered}
          />
        )}
        {!this.props.loaded && (
          <div style={{ padding: "30px 0" }}>
            <Spin x2 />
          </div>
        )}
        {this.props.loaded && !disableWhenFCEExists && (
          <Fragment>
            <div id="page" className="container">
              <div className="row">
                {(!!menuItems.length ||
                  (data.acf.dokumenteAnzeigen && data.acf.dokumente) ||
                  !!this.props.singleData.length) && (
                  <div className="col-md-3">
                    {menuItems.map((item): React$Node => (
                      <div className={css(styles.sidebarLink)}>
                        <Link
                          key={item.title}
                          className={css(styles.sidebarLink)}
                          to={item.url.replace(checkUrl(), "")}
                        >
                          {unescape(item.title)}
                        </Link>
                      </div>
                    ))}

                    {!!this.props.singleData.length && (
                      <Fragment>
                        <br />
                        <br />
                        {this.props.singleData.map((data, index) => (
                          <SideBarContact
                            key={index.toString()}
                            data={data}
                            isAccordion
                          />
                        ))}
                      </Fragment>
                    )}
                  </div>
                )}

                <div className={contentClassName}>
                  <Content>{data.content.rendered}</Content>
                </div>

                {data.acf.dokumenteAnzeigen && (
                  <div className="col-md-12">
                    <Overflow width={1000}>
                      <DocumentsTable
                        onSortChange={this.onSortChange}
                        dokumente={documents}
                        headline="Dokumente"
                      />
                    </Overflow>
                  </div>
                )}
                {data.acf.issues && <IssuesTable issues={data.acf.issues} />}
              </div>
            </div>
          </Fragment>
        )}
        <Footer />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      data: selectPage(state),
      loaded: selectLoaded(state),
      menu: selectMenuData(state),
      singleData: selectSingleContact(state),
    }),
    { fetchPage, clearPage, loadSingleContact }
  )(Page)
);
