// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'

import Overflow from 'components/Overflow'
import { DocumentsTable } from 'components'

import styles from './style'

export default class IssuesTable extends Component<*> {
  state = { activeIssue: 0 }

  render(): React$Node {
    const currentIssue = this.props.issues[this.state.activeIssue]

    return (
      <div className="col-md-12">
        <div className={css(styles.table)}>
          <div className={css(styles.table__tabs)}>
            {this.props.issues.map((issue, index) => (
              <div
                key={index.toString()}
                className={css(
                  styles.table__tab,
                  this.state.activeIssue === index && styles['table__tab--active']
                )}
                role="button"
                onClick={() => this.setState({ activeIssue: index })}
              >
                {issue.title}
              </div>
            ))}
          </div>

          <div className={css(styles.table__content)}>
            <Overflow width={1000}>
              <DocumentsTable
                sortable={false}
                onSortChange={this.onSortChange}
                dokumente={currentIssue.documents}
                headline=""
              />
            </Overflow>
          </div>
        </div>
      </div>
    )
  }
}
