// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'
import type { Props } from './types'

export default class Countdown extends Component<Props, null> {
  constructor(props) {
    super(props)
    this.state = { day: '0', hour: '0', min: '0', sek: '0', distance: '1' }
  }

  handleCountdown = () => {
    if (!this.props.data) return null
    const separators = [' ', ':', '-']
    const splitData = this.props.data.split(new RegExp(separators.join('|'), 'g'))

    const countDownDate = new Date(
      `${splitData[0]}/${splitData[1]}/${splitData[2]} ${splitData[3]}:${splitData[4]}:${
        splitData[5]
      }`
    )

    const now = new Date().getTime()
    const distance = countDownDate - now

    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((distance % (1000 * 60)) / 1000)

    if (distance >= 0) {
      this.setState({ day: days, hour: hours, min: minutes, sek: seconds, distance })
    }
  }

  render() {
    const x = setInterval(() => {
      this.handleCountdown()

      if (this.state.distance < 0) {
        clearInterval(x)
      }
    }, 1000)

    return (
      <div className={css(styles.wrapper)}>
        <div className="row">
          <div className="col-md-3 col-xs-3 col-sm-3">
            <div className={css(styles.inner)}>
              {this.state.day}
              <div className={css(styles.dayName)}>Tage</div>
            </div>
          </div>
          <div className="col-md-3 col-xs-3 col-sm-3">
            <div className={css(styles.inner)}>
              {this.state.hour}
              <div className={css(styles.dayName)}>Std.</div>
            </div>
          </div>
          <div className="col-md-3 col-xs-3 col-sm-3">
            <div className={css(styles.inner)}>
              {this.state.min}
              <div className={css(styles.dayName)}>Min.</div>
            </div>
          </div>
          <div className="col-md-3 col-xs-3 col-sm-3">
            <div className={css(styles.inner)}>
              {this.state.sek}
              <div className={css(styles.dayName)}>Sek.</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
