// @flow

import { CALL_API } from 'utils/callApi'
import type { APIAction } from 'utils/callApi.types'
import { LOAD_MENU_REQUEST, LOAD_MENU_SUCCESS, LOAD_MENU_FAILURE } from './actionTypes'

export default function loadMenu(slug: string): APIAction {
  return {
    [CALL_API]: {
      endpoint: `/menus/v1/menus/${slug}`,
      types: [LOAD_MENU_REQUEST, LOAD_MENU_SUCCESS, LOAD_MENU_FAILURE],
    },
  }
}
