// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { primary, primaryLight, grey0, grey1 } from 'constants/color'

export default StyleSheet.create({
  dropdown: {
    position: 'relative',
    zIndex: 9,
    perspective: 2000,
    WebkitPerspective: 2000,
    color: primary,
  },

  'dropdown--active': {},

  dropdown__item: {
    backgroundColor: grey1,
    fontSize: 13,
    padding: '16px 42px 15px 20px',
    lineHeight: '14px',
    outline: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'all 200ms linear',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    ':after': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      border: '4px solid transparent',
      position: 'absolute',
      top: '50%',
      right: 24,

      marginTop: '-3px', // 5px to center vertically - half of the height (2px)
      borderTopColor: 'currentColor',
    },
  },

  'dropdown__item--active': {
    backgroundColor: primaryLight,
    color: 'white',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,

    ':after': {
      marginTop: '-7px', // 5px to center vertically + half of the height (2px)
      borderTopColor: 'transparent',
      borderBottomColor: 'currentColor',
    },
  },

  dropdown__list: {
    position: 'absolute',
    backgroundColor: 'white',
    top: '100%',
    left: 0,
    right: 0,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.20), inset 0 2px 0 rgba(0,0,0,0.10)',

    transition: 'all 200ms ease-in-out',
    pointerEvents: 'none',
    opacity: 0,
    transformOrigin: '50% 0',
    transform: 'perspective(50em) scaleY(0.8)',
  },

  'dropdown__list--active': {
    pointerEvents: 'initial',
    opacity: 1,
    transformOrigin: '50% 0',
    transform: 'perspective(50em) scaleY(1)',
  },

  dropdown__listItem: {
    fontSize: 13,
    outline: 'none',
    lineHeight: '1em',
    padding: '1em 20px',
    userSelect: 'none',
    cursor: 'pointer',
    transition: 'all 200ms linear',
    ':hover': {
      backgroundColor: grey0,
    },
    ':active': {
      backgroundColor: grey0,
    },
  },
})
