// @flow
import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite/no-important'
import Tooltip from '../Tooltip'

const styles = StyleSheet.create({
  wrapper: {
    marginLeft: 5,
    position: 'relative',
    cursor: 'pointer',
    width: 16,
    height: 16,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default class ArrowIcon extends Component {
  state = { active: false }

  render() {
    const { color, bg, link } = this.props

    return (
      <a
        onMouseEnter={() => this.setState({ active: true })}
        onMouseLeave={() => this.setState({ active: false })}
        className={css(styles.wrapper)}
        style={{ backgroundColor: bg }}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Tooltip title="Go to link" active={this.state.active} />
        <svg
          fill="none"
          height="8"
          viewBox="0 0 10 8"
          width="10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke={color || '#fff'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          >
            <path d="m7.52699 4h-6.52699" />
            <path d="m5.92188 6.82824 3.07684-2.82843-3.07684-2.82842" />
          </g>
        </svg>
      </a>
    )
  }
}
