// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'

import unescape from 'utils/unescape'
import truncate from 'utils/truncate'

import { ZIP, PDF, XLS, PPT, DOC, MP3, MP4 } from 'components/_icons/fileTypes'
import { loadDocuments } from 'modules/documents/actions'
import { selectDocuments, selectLoaded } from 'modules/documents/selectors'

import UpDownUi from '../UpDownUi'
import styles from './style'

const handleClick = () => {
  // eslint-disable-next-line no-console
  console.log('click')
}

class TableDocuments extends Component {
  state = { dokumente: [] }

  static defaultProps = {
    sortable: true,
  }

  componentDidMount = async (): void => {
    let documents

    if (this.props.dokumente) {
      documents = this.props.dokumente
    } else {
      documents = await this.props.loadDocuments()
      documents = documents.response
    }

    documents = await Promise.all(
      documents.map(
        async (doc): * => {
            console.log(doc)
          if (!doc.acf) {
            const partnerName = await this.handlePartner(doc.id || doc.iD)
            doc.partner = partnerName // eslint-disable-line
          }

          return doc
        }
      )
    )

    this.setState({ dokumente: documents })
  }

  iconByMimeType = (type): React$Node => {
    switch (type) {
      case 'application/pdf':
        return <PDF className={css(styles.prevIcon)} />
      case 'audio/mpeg':
        return <MP3 className={css(styles.prevIcon)} />
      case 'application/vnd.ms-excel':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <XLS className={css(styles.prevIcon)} />
      case 'application/zip':
        return <ZIP className={css(styles.prevIcon)} />
      case 'video/mp4':
        return <MP4 className={css(styles.prevIcon)} />
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return <PPT className={css(styles.prevIcon)} />
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <DOC className={css(styles.prevIcon)} />
      default:
        return <span>Unbekanntes Datei-Format</span>
    }
  }

  tableRow = (item, index): React$Node => {
      const title = item.title || item.postTitle
      console.log(item.guid)
      return (
          <tr key={index} className={css(styles.tr, !!(index % 2) && styles.tr__even)}>
              <td className={css(styles.td, styles.th__name)} colSpan={4}>
                  {this.iconByMimeType(item.mimeType || item.postMimeType)}
                  <a rel="noopener noreferrer" target="_blank" href={item.url || item.guid} className={css(styles.link)}>
                      {unescape(truncate(title.rendered, 60))}
                  </a>
              </td>
              <td className={css(styles.td, styles.th__name)}>
                  {item.acf.partner ? item.acf.partner : 'OVB'}
              </td>
              <td className={css(styles.td)}>
                  {moment(item.modified || item.postModified)
                      .locale('de')
                      .format('LL')}
              </td>
              <td className={css(styles.td)}>
                  <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={item.url || item.guid}
                      className={css(styles.link, styles.download)}
                  >
                      <svg
                          className={css(styles.download__svg)}
                          width="18"
                          height="20"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                              d="M16 2h-1V0h-2v2H5V0H3v2H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 5c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H3v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z"
                              fill="#2771C6"
                              fillRule="nonzero"
                          />
                      </svg>
                      ansehen
                  </a>
              </td>
          </tr>
      );
  }

  tableDocRow = (item, index): React$Node => {
      const title = item.title || item.postTitle
      return (
          <tr key={index} className={css(styles.tr, !!(index % 2) && styles.tr__even)}>
              <td className={css(styles.td, styles.th__name)} colSpan={4}>
                  {this.iconByMimeType(item.mimeType || item.postMimeType)}
                  <a rel="noopener noreferrer" target="_blank" href={item.url} className={css(styles.link)}>
                      {unescape(typeof title === 'object' ? title.rendered : title)}
                  </a>
              </td>
              <td className={css(styles.td, styles.th__name)}>
                  {typeof item.acf === 'object' && !Array.isArray(item.acf)
                      ? item.acf.partner || 'OVB'
                      : item.partner || 'OVB'}
              </td>
              <td className={css(styles.td)}>
                  {moment(item.date || item.postDate)
                      .locale('de')
                      .format('LL')}
              </td>
              {/* <td className={css(styles.td)}>{`${Math.round(item.filesize / 1000, 10)} KB`}</td> */}
              <td className={css(styles.td)}>
                  <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={item.url || item.sourceUrl}
                      className={css(styles.link, styles.download)}
                  >
                      <svg
                          className={css(styles.download__svg)}
                          width="18"
                          height="20"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                              d="M16 2h-1V0h-2v2H5V0H3v2H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 5c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H3v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z"
                              fill="#2771C6"
                              fillRule="nonzero"
                          />
                      </svg>
                      ansehen
                  </a>
              </td>
          </tr>
      );
  }

  handlePartner = async (id): Promise<string> => {
    try {
      const response = await fetch(`https://portal.ovb.de/wp-json/wp/v2/media/${id}`)
      if (!response.ok) {
        throw Error(response.statusText)
      }
      const json = await response.json()

      if (Object.prototype.hasOwnProperty.call(json.acf, 'partner')) {
        return json.acf.partner
      }

      return 'OVB'
    } catch (error) {
      console.log(error)
    }
  }

  render(): React$Node {
    const { data } = this.props

    return (
      <table className={css(styles.table)}>
        {this.props.headline && (
          <thead>
            <tr>
              <th className={css(styles.th__heading)} colSpan={this.props.isMasthead ? 4 : 7}>
                {this.props.headline}{' '}
                <span className={css(styles.th__heading__Small)}>{this.props.subline}</span>
              </th>
            </tr>
          </thead>
        )}

        <tbody>
          {!this.props.isMasthead && (
            <tr className={css(styles.tr)}>
              <th className={css(styles.th__td, styles.th__name)} colSpan={4}>
                <div className={css(styles.td__inner)}>
                  Name{' '}
                  {this.props.sortable && (
                    <UpDownUi
                      onUpClick={() => this.props.onSortChange('title,desc')}
                      onDownClick={() => this.props.onSortChange('title,asc')}
                    />
                  )}
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__date)}>
                <div className={css(styles.td__inner)}>Partner</div>
              </th>
              <th className={css(styles.th__td, styles.th__location)}>
                <div className={css(styles.td__inner)}>
                  Datum{' '}
                  {this.props.sortable && (
                    <UpDownUi
                      onUpClick={() => this.props.onSortChange('date,desc')}
                      onDownClick={() => this.props.onSortChange('date,asc')}
                    />
                  )}
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__date)}>
                <div className={css(styles.td__inner)}>Aktion</div>
              </th>
            </tr>
          )}

          {this.props.dokumente &&
            this.props.dokumente.map((item, index) => this.tableDocRow(item, index))}
          {!this.props.dokumente && data && data.map((item, index) => this.tableRow(item, index))}
        </tbody>
      </table>
    )
  }
}

export default connect(
  state => ({
    data: selectDocuments(state),
    loaded: selectLoaded(state),
  }),
  { loadDocuments }
)(TableDocuments)
