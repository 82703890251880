// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import Label from 'components/Label'
import Product from 'components/Product'
import Overflow from 'components/Overflow'

import styles from './style'

export default function SuggestedItems(): React$Element<'section'> {
  return (
    <section className={css(styles.items)}>
      <div className={css(styles.items__headline)}>
        <Label>Empfehlungen</Label>
        <h4>Empfohlene Artikel</h4>
      </div>

      <Overflow width={1100}>
        <div className="row">
          <div className="col-sm-3 col-3">
            <Product />
          </div>
          <div className="col-sm-3 col-3">
            <Product />
          </div>
          <div className="col-sm-3 col-3">
            <Product />
          </div>
          <div className="col-sm-3 col-3">
            <Product />
          </div>
        </div>
      </Overflow>
    </section>
  )
}
