// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'

export default StyleSheet.create({
  main: {
    marginTop: 12,
    marginBottom: 12,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
    textAlign: 'left',
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(142,144,174,0.50)',
  },
  'main--list': {
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  'preview--list': {
    height: 'auto',
    marginRight: 12,
    borderBottom: 'none',
  },
  'preview--listImage': {
    width: 29,
    height: 35,
    marginRight: 14,
    backgroundSize: 'contain',
  },
  preview: {
    height: 150,
    borderBottom: '1px solid #eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  preview__icon: {
    width: 64,
  },
  'preview__icon--list': {
    width: 32,
  },
  link: {
    fontSize: 18,
    color: '#2771C6',
    lineHeight: '28px',
    display: 'block',
    fontWeight: 300,
    marginTop: 15,
  },
  content: { padding: 25, margin: '0' },
  'content--list': {
    display: 'flex',
    padding: 0,
  },
  headline: {
    marginBottom: 18,
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: colors.darkFont,
  },
  'headline--list': {
    margin: 0,
    maxWidth: '100%',
  },
  subline: {
    marginBottom: 18,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: colors.darkFont,
  },
  'subline--list': {
    margin: 0,
  },
  text: {
    opacity: 0.75,
    fontWeight: 400,
    fontSize: 18,
    color: colors.darkFont,
    lineHeight: '28px',
  },
})
