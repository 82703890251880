// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import Overflow from 'components/Overflow'

import styles from './style'

export default function Navigation(): React$Node {
  return (
    <Overflow width={500}>
      <nav className={css(styles.navigation)}>
        <a href="#" className={css(styles.navigation__item)}>
          Antragscenter
        </a>
        <a href="#" className={css(styles.navigation__item)}>
          Warenkorb (7)
        </a>
        <a href="#" className={css(styles.navigation__item)}>
          Wunschliste
        </a>
        <a href="#" className={css(styles.navigation__item)}>
          Meine Bestellungen
        </a>
      </nav>
    </Overflow>
  )
}
