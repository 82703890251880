import React from "react";
import Slider from "react-slick";
import Helmet from "react-helmet";
import { css, StyleSheet } from "aphrodite/no-important";
import { MOBILE } from 'constants/breakpoints'

import { Headline } from "./Typo";

import caret from "assets/arrow-ovb.svg";

export class CustomSlider extends React.Component {
  constructor(props) {
    super(props);
    this.slider = React.createRef();
  }

  render() {
    const data = this.props.data.images;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      className: data.length > 3 ? "image-slider image-slider--big" : "image-slider",
    };

    return (
      <div className={css(styles.wrapper)}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Helmet>
                <link
                  rel="stylesheet"
                  type="text/css"
                  charset="UTF-8"
                  href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
              </Helmet>
              <div className={css(styles.nav)}>
                <Headline type="h2" style={{ margin: 0 }}>
                  {this.props.data.headline}
                </Headline>
              </div>
              
              <div className={css(styles.sliderWrapper)}>
                <Slider {...settings} ref={this.slider}>
                  {data.map((item) => (
                    <div className={css(styles.swiper)}>
                      <div
                        className={css(styles.swiperInner)}
                        style={{
                          backgroundImage: `url(${item.image})`,
                        }}
                      />
                    </div>
                  ))}
                </Slider>
                <div className={css(styles.arrows)}>
                  <div
                    className={css(styles.caret)}
                    onClick={() => this.slider.current.slickPrev()}
                  >
                    <img
                      src={caret}
                      width={25}
                      style={{ transform: "rotate(90deg)" }}
                    />
                  </div>
                  <div
                    className={css(styles.caret)}
                    onClick={() => this.slider.current.slickNext()}
                  >
                    <img
                      src={caret}
                      width={25}
                      style={{ transform: "rotate(-90deg)" }}
                    />
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  nav: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // borderBottom: "1px solid #003366",
    paddingBottom: 15,
    paddingTop: 15,
    marginBottom: 30,
  },
  swipe: {
    height: 400,
  },
  swiperInner: {
    height: 250,
    backgroundSize: "cover",
  },
  headline: {
    margin: 0,
  },
  caret: {
    cursor: "pointer",
  },
  arrows: {
    position: "absolute",
    top: 0,
    left: -30,
    width: "calc(100% + 60px)",
    height: "calc(100% - 50px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [MOBILE]: {
      display: "none"
    },
  },
  wrapper: {
    padding: "50px 12px",
    [MOBILE]: {
      padding: "25px 12px",
    },
  },
  sliderWrapper: {
    position: "relative",
  }
});
