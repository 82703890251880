// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'

import Amount from 'components/Amount'
import PDFDownload from 'components/PDFDownload'

import styles from './style'

export default class Product extends Component {
  state = { favorited: false }

  handleFavorite = () => {
    this.setState(prevState => ({ favorited: !prevState.favorited }))
  }

  render(): React$Element<'div'> {
    const { favorited } = this.state
    const { data } = this.props

    return (
      <div className={css(styles.product)}>
        <div className={css(styles.productHeart)} onClick={this.handleFavorite}>
          <div
            className={css(styles.productHeartInner, favorited && styles.productHeartInnerActive)}
          />
        </div>
        <div className={css(styles.productImageHolder)}>
          <img
            className={css(styles.productImage)}
            src={data.betterFeaturedImage.sourceUrl}
            alt=""
          />
        </div>
        <div className={css(styles.productContent)}>
          <div className={css(styles.productHeadline)}>{data.title.rendered}</div>
          <div className={css(styles.productSubline)}>{data.acf.kurzbeschreibung}</div>
          <div className={css(styles.productPrice)}>
            {data.acf.preis}€
            <span className={css(styles.productPriceInfo)}>
              ({data.acf.preisinklmwst}€ inkl. MwSt.)
            </span>
          </div>
          <PDFDownload link={data.acf.produktdatei} style={{ marginTop: 25, marginBottom: 25 }}>
            PDF
          </PDFDownload>
          <Amount data={data} />
        </div>
      </div>
    )
  }
}
