// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import Button from 'components/Button'

import styles from './style'

export default function Totals(): React$Element<'section'> {
  return (
    <section className={css(styles.totals)}>
      <div className={css(styles.totals__price)}>
        <span>Gesamtpreis</span>
        <h4>
          108,00€ <span style={{ fontSize: '.6em' }}>129,99€ inkl. MwSt.</span>
        </h4>
      </div>
      <div className={css(styles.totals__callToAction)}>
        <Button to="/">Abschließen</Button>
      </div>
    </section>
  )
}
