// @flow
import { createSelector } from 'reselect'
import type { State as RootState } from '../types'
import type { State } from './types'

export const selectPartnersTree = (state: RootState): State => state.partners

export const selectLoaded = createSelector(selectPartnersTree, tree => tree.loaded)
export const selectPartners = createSelector(selectPartnersTree, tree => tree.data)
export const selectBranches = createSelector(selectPartnersTree, tree => tree.branches)
