// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'

import link from 'assets/link@2x.png'
import linkwhite from 'assets/link-white@2x.png'
import truncate from 'utils/truncate'

import styles from './style'
import type { Props, State, Type } from './types'

export default class Document extends Component<Props, State> {
  state = { hover: false, read: false }

  componentDidMount() {
    if (localStorage.getItem(`documents-${this.props.index}`) === 'true') {
      this.setState({ read: true })
    }
  }

  handleStorage = () => {
    if (localStorage.getItem(`documents-${this.props.index}`) === 'false') {
      localStorage.setItem(`documents-${this.props.index}`, 'true')
      this.setState({ read: true })
    }
  }

  handleHover = (type: Type) => {
    switch (type) {
      case 'enter':
        this.setState({ hover: true })
        break
      case 'leave':
        this.setState({ hover: false })
        break
      default:
        this.setState({ hover: false })
    }
  }

  render(): React$Node {
    const { hover, read } = this.state
    const { data } = this.props

    return (
      <a
        href={data.url}
        target="_blank"
        rel="noreferrer noopener"
        className={css(
          styles.main,
          hover && styles.mainActive,
          !!(this.props.index % 2) && styles.new
        )}
        onClick={this.handleStorage}
        onMouseEnter={() => this.handleHover('enter')}
        onMouseLeave={() => this.handleHover('leave')}
      >
        <div className={css(styles.mainFlex)}>
          <div className={css(styles.mainIconHolder, hover && styles.mainIconHolderActive)}>
            <img src={linkwhite} alt="" className={css(styles.mainIcon)} />
          </div>
          <p className={css(styles.mainText, hover && styles.mainTextActive)}>
            {truncate(data.title, 80)}
            {/* {data.date} */}
          </p>
          {/* <p className={css(styles.mainExtra, hover && styles.mainExtraActive)}>in Allgemeines</p> */}
        </div>
        <p className={css(styles.mainAgo, hover && styles.mainAgoActive)}>
          {read ? '✔ Gelesen' : '✗ Ungelesen'}
        </p>
      </a>
    )
  }
}
