// @flow
import { eachDay } from 'date-fns'
import _ from 'lodash'

import {
  LOAD_EVENTS_REQUEST,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENTS_FAILURE,
  LOAD_EVENTSPAGE_REQUEST,
  LOAD_EVENTSPAGE_SUCCESS,
  LOAD_EVENTSPAGE_FAILURE,
} from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  pageLoading: false,
  data: [],
}

function handleMultiDayEvents(response) {
  const filtered = response.filter(a => a.acf.hasenddate)
  const newElements = []

  function getDatesInRange(startDate, endDate) {
    return eachDay(startDate, endDate)
  }

  for (let index = 0; index < filtered.length; index++) {
    const e = _.cloneDeep(filtered[index])
    const dates = getDatesInRange(e.acf.datum, e.acf.datumEnde)

    for (let index = 0; index < dates.length; index++) {
      if (index !== 0) {
        const newElement = _.cloneDeep(e)
        const date = new Date(dates[index])
        date.setDate(date.getDate() + 1)
        newElement.acf.datum = date.toISOString().split('T')[0]
        newElements.push(newElement)
      }
    }
  }

  return newElements
}

export const reducer = (state: State = initialState, { type, response }: Action): State => {
  switch (type) {
    case LOAD_EVENTS_REQUEST:
      return { ...state, loaded: false }

    case LOAD_EVENTSPAGE_REQUEST:
      if (state.data.length !== 0) return { ...state, pageLoading: true }
      return null

    case LOAD_EVENTS_SUCCESS:
      const newElements = handleMultiDayEvents(response)

      return { ...state, loaded: true, data: [...response, ...newElements] }

    case LOAD_EVENTSPAGE_SUCCESS:
      const data = handleMultiDayEvents(response)

      return { ...state, pageLoading: false, data: [...state.data, ...response, ...data] }

    case LOAD_EVENTSPAGE_FAILURE:
      return initialState

    case LOAD_EVENTS_FAILURE:
      return initialState

    default:
      return state
  }
}
