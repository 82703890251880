// @flow
import React from 'react'
import Helmet from 'react-helmet'
import { css } from 'aphrodite/no-important'

import styles from './style'

export default function About(): React$Element<'div'> {
  return (
    <div className={css(styles.main)}>
      <Helmet>
        <title>iframe Test Seite</title>
      </Helmet>

      <div className="container">
        <iframe
          title="makleraktiv"
          // width="100%"
          height="900px"
          style={{ maxWidth: 690, width: '100%' }}
          src="https://www.makleraktiv.de/?specialSignIn=gxvPt3j6rI&untervermittler=test"
          frameBorder="0"
        />
      </div>
    </div>
  )
}
