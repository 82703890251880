import React from "react";
import { css, StyleSheet } from "aphrodite/no-important";

import { MOBILE } from 'constants/breakpoints'
import { Headline } from "./Typo";

import downloadIcon from "assets/download-fce.png";

function formatFileSize(bytes, decimalPoint) {
  if (bytes == 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export class Downloads extends React.Component {
  render() {
    const data = this.props.data.datei;

    return (
      <div className={css(styles.wrapper)}>
        <div className="container">
          <Headline type="h2" style={{ margin: 0 }}>
            Downloads
          </Headline>
          <div className="row">
            {data.map((item) => (
              <div className="col-sm-12">
                <div className={css(styles.item)}>
                  <a className={css(styles.title)} target="_blank" href={item.datei.url} download>
                    {item.datei.title}
                  </a>
                  <div>{item.datei.caption}</div>
                  <div>{formatFileSize(item.datei.filesize)}</div>
                  <a target="_blank" href={item.datei.url} download>
                    <img className={css(styles.icon)} src={downloadIcon} />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    padding: "50px 12px",
    [MOBILE]: {
      padding: "25px 12px",
    },
  },
  main: {
    width: "100%",
  },
  item: {
    position: "relative",
    borderBottom: "1px solid rgba(35, 94, 161, 1)",
    paddingBottom: 15,
    paddingTop: 40,
    paddingRight: 50,
    color: "#212529",
  },
  icon: {
    position: "absolute",
    right: 0,
    bottom: 15,
    width: 50,
    height: 50,
  },
  title: {
    fontSize: 16,
    color: "#003366",
    textDecoration: "underline !important",
  }
});
