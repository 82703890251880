// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'

import caret from 'assets/caret-blue.png'

import styles from './style'
import type { Props, State } from './types'

class Accordion extends Component<Props, State> {
  state = { active: false }

  handleExpand = () => {
    this.setState((prevState: State) => ({ active: !prevState.active }))
  }

  render(): React$Node {
    const { active } = this.state

    return (
      <div className={css(styles.main)}>
        <div
          role="button"
          tabIndex="0"
          className={css(styles.mainTitle)}
          onClick={() => this.handleExpand()}
        >
          {this.props.title}
          <img
            src={caret}
            alt=""
            className={css(styles.mainArrow, active && styles.mainArrowActive)}
          />
        </div>
        <div className={css(styles.mainBody, active && styles.mainBodyActive)}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Accordion
