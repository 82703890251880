// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

export default StyleSheet.create({
  table: {
    width: '100%',
    backgroundColor: '#fff',
    // boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    tableLayout: 'fixed',
    fontSize: 18,
    borderCollapse: 'collapse',
  },
  tr: {
    backgroundColor: '#fff',
    height: 55,
  },
  th__heading: {
    fontSize: 20,
    textAlign: 'left',
    padding: '30px 20px 10px',
    color: colors.primary,
  },
  th__heading__Small: {
    fontSize: '0.7em',
    marginLeft: 20,
  },
  tr__even: {
    backgroundColor: '#f6f8fa',
  },
  th__td: {
    color: '#000',
    textAlign: 'left',
    paddingLeft: 20,
  },
  td__small: {
    paddingLeft: '20px !important',
  },
  th__name: {
    width: 200,
  },
  th__date: {
    // width: 170,
  },
  th__location: {
    // width: 130,
  },
  th__from: {
    // width: 85,
  },
  th__to: {
    // width: 85,
  },
  th__category: {
    // width: 170,
  },
  td: {
    color: '#406c8c',
    fontSize: 16,
    paddingLeft: 20,
  },
  link: {
    color: '#2771c6',
    cursor: 'pointer',
  },
  download: {
    display: 'flex',
    alignItems: 'center',
  },
  download__svg: {
    marginRight: 10,
  },
  td__inner: {
    display: 'flex',
    alignItems: 'center',
  },
})
