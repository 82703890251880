// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

export default StyleSheet.create({
  main: {
    display: 'flex',
    width: '100%',
  },
  btnLight: {
    background: '#F2F3F4',
    color: '#C5CACF',
  },
  btn: {
    userSelect: 'none',
    flex: '0 0 72px',
    width: 72,
    height: 48,
    background: '#2771C6',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  },
  input: {
    borderTop: '1px solid #f2f3f4',
    borderBottom: '1px solid #f2f3f4',
    height: 48,
    background: '#fff',
    color: colors.darkFont,
    flex: '1 1 100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
