// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'

import { selectAuthTree } from '../selectors'

export const selectLoginTree: Selector<*, *, boolean> = createSelector(
  selectAuthTree,
  tree => tree.login
)

export const selectLoaded: Selector<*, *, boolean> = createSelector(
  selectLoginTree,
  tree => tree.loaded
)

export const selectUserData: Selector<*, *, Object> = createSelector(
  selectLoginTree,
  tree => tree.data
)

export const selectUserError: Selector<*, *, Object> = createSelector(
  selectLoginTree,
  tree => tree.error
)
