// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'
import { MOBILE, TABLET } from 'constants/breakpoints'

export default StyleSheet.create({
  main: {
    position: 'relative',
    backgroundColor: '#005f72',
    padding: '100px 0',
    [MOBILE]: {
      padding: '50px 0',
    },
  },
  preline: {
    opacity: 0.75,
    fontSize: 16,
    // color: colors.white,
    marginLeft: 0,
  },
  headline: {
    fontSize: 32,
    // color: colors.white,
    margin: '5px 0',
  },
  shape: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '75%',
    width: 'auto',
    [TABLET]: {
      height: '35%',
    },
  },
  logo: {
    position: 'absolute',
    right: 70,
    bottom: '50%',
    marginBottom: -25,
    width: 43,
    height: 51,
    [MOBILE]: {
      right: 24,
    },
  },
  breadcrumb: {
    paddingTop: 30,
  },
  breadcrumbLink: {
    opacity: '1 !important',
    color: '#003A65',
    fontSize: 14,
    textDecoration: 'underline !important',
  },
  breadcrumbCurrent: {
    opacity: 1,
    color: '#000',
    fontSize: 14,
  },
})
