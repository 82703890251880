// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'
import Button from 'components/Button'

import logo from 'assets/logo@2x.png'
import badge from 'assets/jahresmotto2024.png'
import privacyPdf from 'assets/Datenschutzhinweise Portal.pdf'

import Item from './item'
import styles from './style'

export default function Info(props: Object): React$Node {
  return (
    <div {...props} className={css(styles.info__wrapper)}>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className={css(styles.center)}>
              <img src={logo} width={40} alt="" className={css(styles.logo)} />
            </div>
            <br />
            <b>
              <Item name="OVB Vermögensberatung AG" />
            </b>
            <Item name="Heumarkt 1" />
            <Item name="50667 Köln" />
          </div>
          <div className="col-xl-6 col-md-12">
            <div className={css(styles.social)} style={{ display: 'flex', alignItems: 'center' }}>
              <a style={{ marginBottom: 5 }} href="https://www.facebook.com/ovbdeutschland/">
                <img
                  src={require('../../assets/social/OVB_SocialMediaIcon_facebook.png')}
                  style={{ height: 50 }}
                />
              </a>
              &nbsp;
              <a style={{ marginBottom: 5 }} href="https://www.instagram.com/ovbdeutschland/">
                <img
                  src={require('../../assets/social/OVB_SocialMediaIcon_Instagram.png')}
                  style={{ height: 50 }}
                />
              </a>
              &nbsp;
              <a style={{ marginBottom: 5 }} href="https://de.linkedin.com/company/ovbdeutschland">
                <img
                  src={require('../../assets/social/OVB_SocialMediaIcon_Linkedin.png')}
                  style={{ height: 50 }}
                />
              </a>
              &nbsp;
              <a
                style={{ marginBottom: 5 }}
                href="https://www.xing.com/companies/ovbverm%C3%B6gensberatungag"
              >
                <img
                  src={require('../../assets/social/OVB_SocialMediaIcon_Xing.png')}
                  style={{ height: 50 }}
                />
              </a>
              &nbsp;
              <a
                style={{ marginBottom: 5 }}
                href="https://www.youtube.com/channel/UCBNG_YlIYxzWCEYEDB9QbRw"
              >
                <img
                  src={require('../../assets/social/OVB_SocialMediaIcon_youtube.png')}
                  style={{ height: 50 }}
                />
              </a>
              &nbsp;
              <a style={{ marginBottom: 5 }} href="https://www.ovb.de/blog.html">
                <img
                  src={require('../../assets/social/OVB_SocialMediaIcon_blog.png')}
                  style={{ height: 50 }}
                />
              </a>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <div>
                <Item left name="Telefon">
                  0221-2015 0
                </Item>
                <Item left name="Telefax">
                  0221-2015 189
                </Item>
                <Item left name="E-Mail">
                  <a
                    className={css(styles.info, styles.info__left, styles.info__link)}
                    href="mailto:hotline@ovb.de"
                  >
                    hotline@ovb.de
                  </a>
                </Item>
              </div>
              <div>
                <Button
                  style={{ marginBottom: 5 }}
                  to="https://www.ovb.de/service-faq/impressum.html"
                >
                  Impressum
                </Button>
                &nbsp;
                <Button style={{ marginBottom: 5 }} external to={privacyPdf}>
                  Datenschutzhinweise
                </Button>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-md-12">
            <img src={badge} height={155} alt="" className={css(styles.logo)} />
          </div>
        </div>
      </div>
    </div>
  )
}
