// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  main: {
    marginBottom: 14,
    marginTop: 14,
    border: '1px solid #d5dfe5',
  },
  mainLogo: {
    maxHeight: 35,
    width: 'auto',
  },
  mainLogoHolder: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 100,
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    borderBottom: '1px solid #d5dfe5',
  },
  mainLogoHolderOpen: {},
  mainContent: { maxHeight: 0, overflow: 'hidden' },
  mainContentOpen: { maxHeight: 'none' },
  mainBottom: {
    display: 'flex',
    alignItems: 'center',
    height: 70,
    paddingLeft: 24,
    paddingRight: 24,
  },
  mainLink: {
    fontSize: 16,
    color: '#2771C6',
  },
  thirdLink: {
    fontSize: 16,
    color: '#2771C6',
    display: 'inline-block',
  },
})
