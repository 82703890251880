// @flow

import Cookies from 'js-cookie'
import { CALL_API } from 'utils/callApi'
import {
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILURE,
} from './actionTypes'

export default function validateUserToken(): * {
  return {
    [CALL_API]: {
      endpoint: '/jwt-auth/v1/token/validate',
      types: [VALIDATE_TOKEN_REQUEST, VALIDATE_TOKEN_SUCCESS, VALIDATE_TOKEN_FAILURE],
      options: {
        method: 'POST',
        body: {},
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      },
    },
  }
}
