// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'
import { MOBILE } from 'constants/breakpoints'

export default StyleSheet.create({
  main: {
    marginLeft: -30,
    width: 'calc(100% + 56px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // transition: 'all 300ms ease',
    borderLeft: '4px solid transparent',
    borderBottom: `1px solid #DCE4EA`,
    paddingRight: 30,
    textDecoration: 'none',
    [MOBILE]: {
      minWidth: 550,
    },
    ':last-of-type': {
      borderBottom: 'none',
    },
    ':hover': {
      textDecoration: 'none',
    },
  },
  mainActive: {
    background: colors.primary,
  },
  mainFlex: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  new: {
    borderLeft: '4px solid #E61757',
  },
  mainIconHolder: {
    flex: '0 0 32px',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.primary,
    marginLeft: 24,
    marginRight: 12,
  },
  mainIconHolderActive: {
    background: 'transparent',
  },
  mainIcon: {
    width: 16,
    height: 16,
  },
  mainText: {
    fontWeight: 500,
    fontSize: 14,
    color: colors.darkFont,
    minWidth: 1,
    flex: '1 1 100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    // transition: 'all 300ms ease',
  },
  mainTextActive: {
    color: colors.white,
    textDecoration: 'none',
  },
  mainExtra: {
    marginLeft: 40,
    marginRight: 40,
    fontWeight: 500,
    fontSize: 12,
    color: colors.darkFont,
    textAlign: 'right',
    flex: 1,
  },
  mainExtraActive: { color: '#fff' },
  mainAgo: {
    flex: '0 0 auto',
    width: 90,
    marginLeft: 10,
    opacity: 0.5,
    fontWeight: 500,
    fontSize: 14,
    color: colors.darkFont,
    textAlign: 'right',
  },
  mainAgoActive: {
    color: '#fff',
  },
})
