// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  table: {
    margin: '30px 0',
  },

  table__tabs: {
    margin: '0 -2px',
  },

  table__tab: {
    backgroundColor: '#f6f8fa',
    display: 'inline-block',
    margin: '0 2px',
    padding: '10px 20px',
    cursor: 'pointer',
    transition: 'background-color 200ms linear',

    ':hover': {
      backgroundColor: '#d6e0e8',
    },
  },

  'table__tab--active': {
    backgroundColor: '#d6e0e8',
  },

  table__content: {},
})
