// @flow
import React, { Component, Fragment } from 'react' // eslint-disable-line no-unused-vars
import { orderBy } from 'lodash'
import { css } from 'aphrodite/no-important'
import dateFormat from 'dateformat' // eslint-disable-line no-unused-vars
import { convertTime } from '../../_modules/EventWeek/helper'

import UpDownUi from '../UpDownUi'

import styles from './style'

import type { Props } from './types'
import { downloadFile } from '../../_modules/EventWeek/utils'

function save(item) {
  downloadFile(item)
}

const TableRow = (item: Object, index: number, isMasthead: ?boolean): React$Node => (
  <tr key={item.id} className={css(styles.tr, !!(index % 2) && styles.tr__even)}>
    <td className={css(styles.td, styles.td__small, styles.th__name)} colSpan={2}>
      <a className={css(styles.link)}>{item.title.rendered}</a>
    </td>
    {!isMasthead && (
      <Fragment>
        <td className={css(styles.td, styles.td__small)}>{item.acf.datum}</td>
      </Fragment>
    )}
    {isMasthead && (
      <Fragment>
        <td className={css(styles.td, styles.td__small)} style={{ padding: 0 }}>
          {item.acf.ort}
        </td>
        <td className={css(styles.td, styles.td__small)} style={{ padding: 0 }}>
          {item.acf.start && convertTime(item.acf.start)}
        </td>
        <td className={css(styles.td, styles.td__small)} style={{ padding: 0 }}>
          {item.acf.end && convertTime(item.acf.end)}
        </td>
        {/* {item.acf.link && (
          <td className={css(styles.td, styles.td__small)} style={{ padding: 0 }}>
            <a
              className={css(styles.link)}
              href={item.acf.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Zum Link
            </a>
          </td>
        )} */}
      </Fragment>
    )}
    {item.acf.ort.adresse && (
      <td className={css(styles.td, styles.td__small)}>{item.acf.ort.adresse}</td>
    )}
    {!isMasthead && (
      <Fragment>
        <td className={css(styles.td, styles.td__small)}>
          {item.acf.start && convertTime(item.acf.start)}
        </td>
        <td className={css(styles.td, styles.td__small)}>
          {item.acf.end && convertTime(item.acf.end)}
        </td>
      </Fragment>
    )}
    <td className={css(styles.td, styles.td__small)}>
      {item.acf.link && (
        <a
          className={css(styles.link)}
          href={item.acf.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Beitreten
        </a>
      )}
    </td>
  </tr>
)

export default class TableEvents extends Component<Props, void> {
  state = { data: this.props.data, lastVal: '', wrongOrder: true }

  componentWillUpdate(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.handleData(nextProps.data)
    }
  }

  handleData = data => this.setState({ data })

  handleSort = val => {
    if (this.state.lastVal === val) {
      this.setState(prevState => ({ wrongOrder: !prevState.wrongOrder }))
    }

    this.setState(prevState => ({
      lastVal: val,
      data: orderBy(prevState.data, [o => o[val]], [prevState.wrongOrder ? 'asc' : 'desc']),
    }))
  }

  render(): React$Element<'table'> {
    const { data } = this.state

    return (
      <table className={css(styles.table)}>
        {this.props.headline && (
          <thead>
            <tr>
              <th className={css(styles.th__heading)} colSpan={this.props.isMasthead ? 4 : 7}>
                {this.props.headline}{' '}
                <span className={css(styles.th__heading__Small)}>{this.props.subline}</span>
              </th>
            </tr>
          </thead>
        )}

        <tbody>
          {true && (
            <tr className={css(styles.tr)}>
              <th className={css(styles.th__td, styles.th__name)} colSpan={2}>
                <div className={css(styles.td__inner)}>
                  Name{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('name')}
                    onDownClick={() => this.handleSort('name')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__location)}>
                <div className={css(styles.td__inner)}>
                  Ort{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('location')}
                    onDownClick={() => this.handleSort('location')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__from)}>
                <div className={css(styles.td__inner)}>
                  Von{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('von')}
                    onDownClick={() => this.handleSort('von')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__to)}>
                <div className={css(styles.td__inner)}>
                  Bis{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('bis')}
                    onDownClick={() => this.handleSort('bis')}
                  />
                </div>
              </th>
              <th className={css(styles.th__td, styles.th__date)}>
                <div className={css(styles.td__inner)}>
                  Link{' '}
                  <UpDownUi
                    onUpClick={() => this.handleSort('link')}
                    onDownClick={() => this.handleSort('link')}
                  />
                </div>
              </th>
            </tr>
          )}

          {data && data.map((item, index) => TableRow(item, index, this.props.isMasthead))}
        </tbody>
      </table>
    )
  }
}
