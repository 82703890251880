// @flow

import { INCREASE_FONT, DECREASE_FONT } from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  size: 16,
}

export const reducer = (state: State = initialState, { type }: Action): State => {
  switch (type) {
    case INCREASE_FONT:
      if (state.size >= 20) {
        return { size: 20 }
      }

      return { size: state.size + 1 }

    case DECREASE_FONT:
      if (state.size <= 14) {
        return { size: 14 }
      }

      return { size: state.size - 1 }
    default:
      return state
  }
}
