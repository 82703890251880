// @flow

import { CALL_API } from 'utils/callApi'
import {
  LOAD_PRODUCTS_REQUEST,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  LOAD_PRODUCTCATEGORIES_REQUEST,
  LOAD_PRODUCTCATEGORIES_SUCCESS,
  LOAD_PRODUCTCATEGORIES_FAILURE,
  ORDER_BY,
  ORDER_DIRECTION,
  SET_FILTER,
} from './actionTypes'

export function loadProducts(): * {
  return {
    [CALL_API]: {
      endpoint: '/wp/v2/produkte',
      types: [LOAD_PRODUCTS_REQUEST, LOAD_PRODUCTS_SUCCESS, LOAD_PRODUCTS_FAILURE],
    },
  }
}

export function setOrderBy(type): * {
  return { type: ORDER_BY, payload: type }
}

export function setOrderDirection(type): * {
  return { type: ORDER_DIRECTION, payload: type }
}

export function setFilter(type): * {
  return { type: SET_FILTER, payload: type }
}

export function loadProductCategories(): * {
  return {
    [CALL_API]: {
      endpoint: '/wp/v2/produkt_kategorie',
      types: [
        LOAD_PRODUCTCATEGORIES_REQUEST,
        LOAD_PRODUCTCATEGORIES_SUCCESS,
        LOAD_PRODUCTCATEGORIES_FAILURE,
      ],
    },
  }
}
