// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Transition } from 'react-transition-group'
import { css } from 'aphrodite/no-important'

import toggleModal from 'modules/ui/modal/actions'
import { selectModalOpen } from 'modules/ui/modal/selectors'

import close from 'assets/close.png'
import icloud from 'assets/icloud.png'
import googlecalendar from 'assets/google_calendar.png'
import office365 from 'assets/office365.png'

import Button from '../Button'

import styles from './style'

import type { Props } from './types'

const duration = 600

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 0,
  pointerEvents: 'none',
}

const transitionStyles = {
  entering: {
    opacity: 0,
    pointerEvents: 'none',
  },
  entered: {
    opacity: 1,
    pointerEvents: 'all',
  },
}

function Modal(props: Props): React$Element<'div'> {
  return (
    <Transition in={props.open} timeout={duration}>
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <div className={css(styles.main)}>
            <div
              className={css(styles.mainBG)}
              role="button"
              tabIndex="0"
              onClick={() => props.toggleModal()}
            />
            <div className={css(styles.mainInner)}>
              <div className={css(styles.subline)}>Kalender</div>
              <div className={css(styles.headline)}>
                Wollen sie den Termin in ihrem Kalender speichern?
              </div>
              <div className={css(styles.text)}>
                Sie haben die Möglichkeit zwischen verschiednen Diensten zu wählen.
              </div>
              <div className={css(styles.action)}>
                <div className={css(styles.actionItem)}>
                  <img className={css(styles.actionItemImage)} src={icloud} alt="" />
                </div>
                <div className={css(styles.actionItem)}>
                  <img className={css(styles.actionItemImage)} src={googlecalendar} alt="" />
                </div>
                <div className={css(styles.actionItem)}>
                  <img className={css(styles.actionItemImage)} src={office365} alt="" />
                </div>
              </div>
              <Button to="#">Termin speichern</Button>
              <div
                role="button"
                tabIndex="0"
                onClick={() => props.toggleModal()}
                className={css(styles.closeHolder)}
              >
                <img src={close} alt="" className={css(styles.close)} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  )
}

const mapStateToProps = state => ({
  open: selectModalOpen(state),
})

const mapActionsToProps = { toggleModal }

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Modal)
