// @flow

import {
  LOAD_DOCS_REQUEST,
  LOAD_DOCS_SUCCESS,
  LOAD_DOCS_FAILURE,
  LOAD_DOCS_BY_PAGE_REQUEST,
  LOAD_DOCS_BY_PAGE_SUCCESS,
  LOAD_DOCS_BY_PAGE_FAILURE,
} from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  data: [],
  documentsByPage: {},
}

export const reducer = (
  state: State = initialState,
  { type, response, ...action }: Action
): State => {
  switch (type) {
    case LOAD_DOCS_REQUEST:
      return { ...state, loaded: false }

    case LOAD_DOCS_SUCCESS:
      return { ...state, loaded: true, data: response }

    case LOAD_DOCS_FAILURE:
      return initialState

    case LOAD_DOCS_BY_PAGE_SUCCESS:
      return {
        ...state,
        loaded: true,
        documentsByPage: { ...state.documentsByPage, [action.page]: response },
      }

    case LOAD_DOCS_BY_PAGE_REQUEST:
    case LOAD_DOCS_BY_PAGE_FAILURE:
      return { ...state, loaded: false }
    default:
      return state
  }
}
