// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'

import type { Props, State } from './types'
import styles from './style'

export default class Amount extends Component<Props, State> {
  state = { amount: 0 }

  up = () => this.setState(state => ({ amount: state.amount + 1 }))

  down = () =>
    this.state.amount > 0
      ? this.setState(state => ({ amount: state.amount - 1 }))
      : this.setState({ amount: 0 })

  render(): React$Element<'div'> {
    const { amount } = this.state
    return (
      <div className={css(styles.main)}>
        <div
          className={css(styles.btn, styles.btnLight)}
          role="button"
          tabIndex="0"
          onClick={this.down}
        >
          -
        </div>
        <div className={css(styles.input)}>{amount}</div>
        <div className={css(styles.btn)} role="button" tabIndex="0" onClick={this.up}>
          +
        </div>
      </div>
    )
  }
}
