// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  input: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 10,
    border: '1px solid #eee',
  },

  input__icon: {
    flex: '0 0 50px',
    width: 50,
    height: 50,
    borderRight: '1px solid #eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  input__wrap: {
    flex: '1 1 100%',
  },

  input__entity: {
    height: 50,
    appearance: 'none',
    border: 0,
    outline: 'none',
    padding: '0 20px',
    fontSize: 14,
    color: 'inherit',
    fontFamily: 'inherit',
    margin: 0,
    width: '100%',
  },
})
