// @flow
import { combineReducers } from 'redux'

import { reducer as loginReducer } from './login'
import { reducer as validateReducer } from './validate'

export const reducer = combineReducers({
  login: loginReducer,
  validate: validateReducer,
})
