// @flow
export const LOAD_IMPORTANT_NEWS_REQUEST: string = 'LOAD_IMPORTANT_NEWS_REQUEST'
export const LOAD_IMPORTANT_NEWS_SUCCESS: string = 'LOAD_IMPORTANT_NEWS_SUCCESS'
export const LOAD_IMPORTANT_NEWS_FAILURE: string = 'LOAD_IMPORTANT_NEWS_FAILURE'

export const LOAD_NEWS_REQUEST: string = 'LOAD_NEWS_REQUEST'
export const LOAD_NEWS_SUCCESS: string = 'LOAD_NEWS_SUCCESS'
export const LOAD_NEWS_FAILURE: string = 'LOAD_NEWS_FAILURE'

export const LOAD_NEWSBANNER_REQUEST: string = 'LOAD_NEWSBANNER_REQUEST'
export const LOAD_NEWSBANNER_SUCCESS: string = 'LOAD_NEWSBANNER_SUCCESS'
export const LOAD_NEWSBANNER_FAILURE: string = 'LOAD_NEWSBANNER_FAILURE'

export const LOAD_ARTICLE_REQUEST: string = 'LOAD_ARTICLE_REQUEST'
export const LOAD_ARTICLE_SUCCESS: string = 'LOAD_ARTICLE_SUCCESS'
export const LOAD_ARTICLE_FAILURE: string = 'LOAD_ARTICLE_FAILURE'

export const LOAD_ANSPRECHPARTNER_REQUEST: string = 'LOAD_ANSPRECHPARTNER_REQUEST'
export const LOAD_ANSPRECHPARTNER_SUCCESS: string = 'LOAD_ANSPRECHPARTNER_SUCCESS'
export const LOAD_ANSPRECHPARTNER_FAILURE: string = 'LOAD_ANSPRECHPARTNER_FAILURE'
