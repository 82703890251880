// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'
import { connect } from 'react-redux'
import changeDate from 'modules/calendar/date/actions'
import ReactCalendar from 'react-calendar/dist/entry.nostyle'

import styles from './style'
import type { Props } from './types'

class Calendar extends Component<Props> {
  onChange = (date: Date) => {
    this.props.changeDate(date)
  }

  render(): React$Element<'div'> {
    const events = this.props.events.map(event => ({
      ...event,
      acf: { ...event.acf, datum: moment(event.acf.datum) },
    }))

    return (
      <div>
        <ReactCalendar
          ref={this.props.withRef}
          style={this.props.isSmall && { marginTop: 20 }}
          showNavigation={!this.props.isSmall}
          tileClassName={css(styles.calendar__item)}
          tileContent={({ date, view }): React$Element<*> => {
            const daysEvents = events.filter(
              (event): boolean => event.acf.datum.isSame(date, 'day')
            )

            return view === 'month' && daysEvents.length ? (
              <div className={css(styles.calendar__dots)}>
                {daysEvents.map(() => (
                  <span className={css(styles.calendar__dot)} />
                ))}
              </div>
            ) : null
          }}
          locale="de"
          onChange={this.onChange}
          value={this.props.date.toDate()}
        />
      </div>
    )
  }
}

export default connect(
  null,
  {
    changeDate,
  }
)(Calendar)
