// @flow
import React from 'react'

export default function LockIcon(props: Props): React$Node<'svg'> {
  return (
    <svg width="16" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5555417 8h-1.3333334V6.2222083C14.2222083 2.79125 11.431 0 8 0S1.7777917 2.79125 1.7777917 6.2222083V8H.4444583C.1987917 8 0 8.1987917 0 8.4444583v11.111125c0 .9804584.7972917 1.77775 1.7777917 1.77775H14.22225c.9804583 0 1.77775-.7972916 1.77775-1.7777916V8.4444583C16 8.1987917 15.8012083 8 15.5555417 8zM9.33075 17.2842917c.013875.1254166-.0264583.2512916-.1106667.3455-.0842083.0941666-.204875.148-.3311666.148H7.111125c-.1262917 0-.2469583-.0538334-.3311667-.148-.0842083-.0941667-.1245833-.2200417-.1106666-.3455l.280375-2.52125C6.494375 14.431875 6.22225 13.908 6.22225 13.3333333c0-.9804583.7972917-1.7777916 1.7777917-1.7777916s1.7777916.7972916 1.7777916 1.7777916c0 .5746667-.272125 1.0985417-.7274166 1.4297084l.2803333 2.52125zM11.5555417 8H4.4444583V6.2222083c0-1.9605 1.5950417-3.5555416 3.5555417-3.5555416 1.9605 0 3.5555417 1.5950416 3.5555417 3.5555416V8z"
        fill={props.fill ? props.fill : 'currentColor'}
      />
    </svg>
  )
}
