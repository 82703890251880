import React from "react";
import { Link } from "react-router-dom";
import { css } from "aphrodite/no-important";
import ReactGA from "react-ga";
import Spin from "components/Spin";
import styles from "./style";
import type { Props, AllowedProps } from "./types";

export default function Button(props: Props): React$Node {
  const hasLink = typeof props.to !== "undefined";
  let external;
  let Component = "a";

  if (hasLink) {
    external = props.to.startsWith("http") || props.to.startsWith("mailto");
    Component = props.external || external ? "a" : Link;
  }

  if (props.type === "submit") {
    Component = "button";
  }

  if (props.incognito === true) {
    Component = "div";
  }

  const aProps: AllowedProps = hasLink
    ? { [props.external || external ? "href" : "to"]: props.to }
    : null;

  if (props.external || external) {
    aProps.target = "_blank";
    aProps.rel = "nofollow noreferrer";
  }

  const handleClick = () => {
    props.onClick();
    ReactGA.event({
      category: "Button",
      action: "Button Click",
      label: props.children,
    });
  };

  return (
    <Component
      {...aProps}
      onClick={() => handleClick()}
      type={props.type}
      style={props.style}
      className={css(
        styles.button,
        styles["button--primary"],
        props.isOutline && styles["button--outline"],
        props.isImportant && styles["button--important"],
        props.customclassName,
        props.isSmall && styles["button--small"],
        !!props.block && styles["button--block"]
      )}
    >
      {props.loading ? <Spin color="white" /> : props.children}
    </Component>
  );
}
