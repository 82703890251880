import { StyleSheet } from 'aphrodite/no-important'

const styles = StyleSheet.create({
  overflow: { overflow: 'auto' },
  calendar: {
    minWidth: 1000,
    margin: '5px 0 25px',
    display: 'flex',
    border: '1px solid #DCE4EA',
    borderRight: 'none',
  },
  day: {
    flex: 1,
    padding: 16,
    background: '#fff',
    borderRight: '1px solid #DCE4EA',
  },
  'day--active': {
    background: '#673398',
    color: '#fff',
  },
  dayTop: {
    paddingBottom: 16,
    borderBottom: '1px solid #DCE4EA',
  },
  dayHeadline: {
    fontSize: 16,
    color: '#003A65',
    fontWeight: 600,
  },
  daySubline: {
    fontSize: 16,
    color: '#003A65',
  },
  dayEventList: {
    display: 'inline-block',
  },
  event: {
    display: 'inline-block',
    paddingTop: 16,
  },
  eventTime: {
    fontSize: 13,
    color: '#003A65',
    opacity: 0.75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  eventTitle: {
    marginTop: 2,
    fontSize: 14,
    color: '#003A65',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  download: { width: 16, height: 16, float: 'right' },
  white: { color: '#fff' },
  disabled: { pointerEvents: 'none', cursor: 'initial' },
  flex: { display: 'flex', justifyContent: 'space-between' },
  flexCenter: { alignItems: 'center' },
  navBtns: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    cursor: 'pointer',
    background: 'rgb(0, 58, 101)',
    color: '#fff',
    borderRadius: 5,
    margin: '0 10px -5px',
  },
})

export default styles
