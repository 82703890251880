// @flow

export const SORT = [
  { name: 'Preis aufsteigend', by: 'acf.preis', dir: 'asc' },
  { name: 'Preis absteigend', by: 'acf.preis', dir: 'desc' },
  { name: 'Name aufsteigend', by: 'title.rendered', dir: 'asc' },
  { name: 'Name absteigend', by: 'title.rendered', dir: 'desc' },
  { name: 'Relevanz', by: 'relevanz', dir: 'asc' },
]

export const FILTER = [
  { name: 'zwischen 0€ - 15€', by: [0, 15], dir: 'asc' },
  { name: 'zwischen 15€ - 25€', by: [15, 25], dir: 'asc' },
  { name: 'zwischen 25€ - 45€', by: [25, 45], dir: 'asc' },
  { name: 'zwischen 45€ - 65€', by: [45, 65], dir: 'asc' },
  { name: 'zwischen 65€ - 85€', by: [65, 15], dir: 'asc' },
]
