// @flow

import { CALL_API } from 'utils/callApi'
import { stringify } from 'qs'
import {
  LOAD_DOCS_REQUEST,
  LOAD_DOCS_SUCCESS,
  LOAD_DOCS_FAILURE,
  LOAD_DOCS_BY_PAGE_REQUEST,
  LOAD_DOCS_BY_PAGE_SUCCESS,
  LOAD_DOCS_BY_PAGE_FAILURE,
} from './actionTypes'

export function loadDocuments(): * {
  return {
    [CALL_API]: {
      endpoint: '/wp/v2/media?media_type=application',
      types: [LOAD_DOCS_REQUEST, LOAD_DOCS_SUCCESS, LOAD_DOCS_FAILURE],
    },
  }
}

export function loadDocumentsByPage(page = 1, per_page = 15, { q, ...options } = {}): * {
  const opts = {
    page,
    per_page,
    ...options,
  }

  if (q) {
    opts.query = q
  }

  return {
    [CALL_API]: {
      endpoint: `/eps/v2/media-search?${stringify(opts)}`,
      options: { addition: { page } },
      types: [LOAD_DOCS_BY_PAGE_REQUEST, LOAD_DOCS_BY_PAGE_SUCCESS, LOAD_DOCS_BY_PAGE_FAILURE],
    },
  }
}
