// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

import heart from 'assets/heart.png'
import heartFilled from 'assets/heart--filled.png'

export default StyleSheet.create({
  product: {
    width: '100%',
    padding: 25,
    border: '1px solid #DEE2E7',
    borderRadius: 4,
    marginTop: 12,
    marginBottom: 12,
  },
  productHeart: {
    backgroundImage: `url(${heart})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    cursor: 'pointer',
    right: 24,
    top: 24,
    width: 25,
    height: 25,
  },
  productHeartInner: {
    backgroundImage: `url(${heartFilled})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transform: 'scale(0.7)',
    transition: 'all 300ms ease',
  },
  productHeartInnerActive: {
    opacity: 1,
    transform: 'scale(1)',
  },
  productImageHolder: {
    heigth: 230,
    marginBottom: 15,
  },
  productImage: {
    maxHeigth: '90%',
    maxWidth: '90%',
    margin: '0 auto',
  },
  productContent: {},
  productHeadline: {
    fontSize: 20,
    color: colors.darkFont,
  },
  productSubline: {
    opacity: 0.75,
    fontSize: 14,
    color: '#000',
    lineHeight: '20px',
    marginTop: 10,
    marginBottom: 10,
  },
  productPrice: {
    fontSize: 18,
    color: '#2771C6',
    display: 'flex',
    alignItems: 'center',
  },
  productPriceInfo: {
    opacity: 0.75,
    fontSize: 14,
    color: colors.darkFont,
    lineHeight: '20px',
    marginLeft: 10,
  },
})
