import React from "react";
import { Button } from "components";
import { css, StyleSheet } from "aphrodite/no-important";
import { MOBILE } from 'constants/breakpoints'

import { Headline, Text } from "./Typo";
export class ContentGrid extends React.Component {
  render() {
    const { block } = this.props.data;
    return (
      <div className={css(styles.wrapper)}>
        <div className="container">
          <div className="row">
            {block.map((item) => (
              <div className="col-md-4 col-sm-6 col-xs-12">
                <ContentBlock data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

class ContentBlock extends React.Component {
  render() {
    const {
      img,
      headline,
      subline,
      text,
      buttonLink,
      buttonText,
      background,
      textColor,
      isOutlineButton,
    } = this.props.data;
    return (
      <div className={css(styles.block)} style={{ background }}>
        <div
          className={css(styles.img)}
          style={{
            backgroundImage: `url(${img})`,
          }}
        />
        <div className={css(styles.content)} style={{ color: textColor }}>
          <Headline type="h2" style={{ margin: 0, color: textColor }}>
            {headline}
          </Headline>
          <Headline type="h3" style={{ margin: 0, color: textColor }}>
            {subline}
          </Headline>
          <Text style={{ color: textColor }}>{text}</Text>
          <Button isOutline={isOutlineButton} to={buttonLink} isSmall>
            {buttonText}
          </Button>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    margin: "50px 12px",
    maxWidth: "100%",
    [MOBILE]: {
      padding: "25px 12px",
    },
  },
  block: {
    marginBottom: 5,
    marginTop: 5,
  },
  img: {
    width: "100%",
    height: 200,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  headline: {
    fontSize: 20,
    marginBottom: 10,
  },
  subline: {
    fontSize: 18,
  },
  text: {
    fontSize: 15,
    margin: "10px 0 20px",
  },
  content: {
    padding: 24,
  },
});
