// @flow
import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'

import { Video, Article } from 'components'
import { loadFeaturedVideos } from 'modules/videos/actions'
import { selectVideos } from 'modules/videos/selectors'
import boxStyles from 'components/Box/style'
import styles from './style'

class VideoSlider extends Component {
  componentDidMount = () => this.props.loadFeaturedVideos()

  render(): React$Node {
    return (
      <div
        className="col-md-12"
        style={{ backgroundColor: '#026666', padding: '14px 14px 0', marginTop: 10 }}
      >
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className={css(styles.videoHolder)}>
              <Video />
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div
              className={css(
                boxStyles.box,
                boxStyles['box--related'],
                boxStyles['box--flex'],
                boxStyles['box--overWidth']
              )}
            >
              {this.props.videos.map(
                (item, index): React$Node => {
                  if (index <= 1) {
                    return (
                      <Article
                        key={JSON.stringify(index)}
                        headline={item.title.rendered}
                        text={item.acf.kurzbeschreibung}
                        index={index}
                        isControl
                        isActive={index === 0}
                        video={item}
                      />
                    )
                  }

                  return null
                }
              )}

              {this.props.isHome && (
                <Fragment>
                  <br />
                  <Link className={css(styles.moreLink)} to="/videos">
                    {`>`}
                  </Link>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({ videos: selectVideos(state) }),
  { loadFeaturedVideos }
)(VideoSlider)
