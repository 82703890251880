// @flow
import {
  LOAD_IMPORTANT_NEWS_REQUEST,
  LOAD_IMPORTANT_NEWS_SUCCESS,
  LOAD_IMPORTANT_NEWS_FAILURE,
  LOAD_NEWS_REQUEST,
  LOAD_NEWS_SUCCESS,
  LOAD_NEWS_FAILURE,
  LOAD_NEWSBANNER_REQUEST,
  LOAD_NEWSBANNER_SUCCESS,
  LOAD_NEWSBANNER_FAILURE,
  LOAD_ARTICLE_REQUEST,
  LOAD_ARTICLE_SUCCESS,
  LOAD_ARTICLE_FAILURE,
  LOAD_ANSPRECHPARTNER_REQUEST,
  LOAD_ANSPRECHPARTNER_SUCCESS,
  LOAD_ANSPRECHPARTNER_FAILURE,
} from "./actionTypes";

import type { State, Action } from "./types";

const initialState: State = {
  loaded: false,
  data: [],

  articleLoaded: false,
  article: [],

  newsbanner: [],

  important: {},
  erstePDF: {},
  zweitePDF: {},
};

export const reducer = (
  state: State = initialState,
  { type, response }: Action
): State => {
  switch (type) {
    case LOAD_NEWSBANNER_REQUEST:
      return { ...state };

    case LOAD_NEWSBANNER_SUCCESS:
      return { ...state, newsbanner: response };

    case LOAD_NEWSBANNER_FAILURE:
      return initialState;

    case LOAD_ANSPRECHPARTNER_REQUEST:
      return { ...state };

    case LOAD_ANSPRECHPARTNER_SUCCESS:
      return {
        ...state,
        erstePDF: response.acf.erstePdf,
        zweitePDF: response.acf.zweitePdf,
      };

    case LOAD_ANSPRECHPARTNER_FAILURE:
      return initialState;

    case LOAD_NEWS_REQUEST:
      return { ...state, loaded: false };

    case LOAD_NEWS_SUCCESS:
      return { ...state, loaded: true, data: response };

    case LOAD_NEWS_FAILURE:
      return initialState;

    case LOAD_IMPORTANT_NEWS_REQUEST:
      return { ...state };

    case LOAD_IMPORTANT_NEWS_SUCCESS:
      return { ...state, important: response.acf };

    case LOAD_IMPORTANT_NEWS_FAILURE:
      return initialState;

    case LOAD_ARTICLE_REQUEST:
      return { ...state, article: response };

    case LOAD_ARTICLE_SUCCESS:
      return { ...state, articleLoaded: true, article: response[0] };

    case LOAD_ARTICLE_FAILURE:
      return initialState;

    default:
      return state;
  }
};
