import React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { MOBILE } from 'constants/breakpoints'

export class Accordions extends React.Component {
  render() {
    if (!this.props.data.accordion) return null;

    return (
      <div className={css(styles.wrapper)}>
        <div
          className="container fce-accordions"
          dangerouslySetInnerHTML={{ __html: this.props.data.accordion }}
        ></div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    margin: "50px 0",
    [MOBILE]: {
      margin: "25px 0",
    },
  },
});
