// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'
import type { State as RootState } from 'modules/types'

import { selectUiTree } from '../selectors'
import type { State } from './types'

export const selectSearchTree: Selector<RootState, *, State> = createSelector(
  selectUiTree,
  tree => tree.search
)

export const selectSearchState: Selector<RootState, *, boolean> = createSelector(
  selectSearchTree,
  tree => tree.open
)
