// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { css } from 'aphrodite/no-important'

import unescape from 'utils/unescape'
import styles from './style'
import type { Props } from './types'

const searchForCipher = /(\%5B|\[)cipher(\%5D|\])/
// const searchForCipher = /infokompakt/

export default function Menu(props: Props): React$Element<'div'> {
  return (
    <div className={css(styles.menu, props.active && styles.menuActive)}>
      <div className="container">
        <div className={css(styles.menuFlex, props.active && styles.menuFlexActive)}>
          {props.entries.map((entry, index): React$Node => {
            if (entry.link === '/shop/') {
              return (
                <a
                  key={index.toString()}
                  href={entry.link}
                  target="_blank"
                  className={css(styles.menuItem)}
                  rel="noreferrer"
                >
                  {unescape(entry.title)}
                </a>
              )
            }

            if (entry.link.match(searchForCipher)) {
              if (props.cipher) {
                return (
                  <a
                    key={index.toString()}
                    href={entry.link.replace(searchForCipher, props.cipher)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css(styles.menuItem)}
                  >
                    {unescape(entry.title)}
                  </a>
                )
              }

              return null
            }

            if (entry.link.startsWith('http')) {
              return (
                <a
                  key={index.toString()}
                  href={entry.link}
                  target="_blank"
                  className={css(styles.menuItem)}
                  rel="noreferrer"
                >
                  {unescape(entry.title)}
                </a>
              )
            }

            return (
              <Link
                key={index.toString()}
                to={entry.link}
                className={css(styles.menuItem)}
                onClick={() => props.onClick()}
              >
                {unescape(entry.title)}
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}
