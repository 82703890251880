// @flow
import { combineReducers } from 'redux'

import { reducer as fontReducer } from './font'
import { reducer as modalReducer } from './modal'
import { reducer as videoReducer } from './video'
import { reducer as searchReducer } from './search'

export const reducer = combineReducers({
  font: fontReducer,
  modal: modalReducer,
  video: videoReducer,
  search: searchReducer,
})
