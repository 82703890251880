// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'
import type { Selector } from 'reselect'

import { fetchPage } from 'modules/page/actions'

import { loadDocuments } from 'modules/documents/actions'

import { Document, Button } from 'components'
import Headline from 'components/Box/Headline'
import boxStyles from 'components/Box/style'
import type { Props } from './types'

class Documents extends Component<Props> {
  state = { page: false }

  componentDidMount = async (): Promise<*> => {
    fetch('https://portal.ovb.de/wp-json/wp/v2/pages?slug=info-kompakt')
      .then(response => response.json())
      .then(data => this.setState({ page: data[0] }))
  }

  handleStorage = index => {
    if (localStorage.getItem(`documents-${index}`) === null) {
      localStorage.setItem(`documents-${index}`, 'false')
    }
  }

  render(): React$Node {
    const { page } = this.state

    if (
      page &&
      typeof page.acf === 'object' &&
      Array.isArray(page.acf.issues) &&
      page.acf.issues.length > 0
    ) {
      const issue = page.acf.issues[0]

      return (
        <div style={{ marginTop: 20 }}>
          <div className={css(boxStyles.flex)}>
            <Headline isBig headline={`Infokompakt (${issue.title})`} />
            <Button to="/ovb/info-kompakt/">Alle anzeigen</Button>
          </div>
          <div className={css(boxStyles.box, boxStyles['box--module'], boxStyles['box--white'])}>
            {issue.documents.map(
              (item, index): React$Node => {
                this.handleStorage(index)
                return <Document data={item} index={index} key={JSON.stringify(index)} />
              }
            )}
          </div>
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = null

const mapActionsToProps = {
  fetchPage,
  loadDocuments,
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Documents)
