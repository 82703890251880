// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  videoHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 10,
  },
  moreLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 22px',
    color: 'white',
    background: '#6ca3a3',
  },
})
