// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  main: {
    textAlign: 'left',
  },
  dropdownHolder: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionHolder: {
    width: '100%',
    padding: '20px 0',
  },
  alignRight: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 0',
  },
})
