// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'

import { selectAuthTree } from '../selectors'

export const selectValidateTree: Selector<*, *, boolean> = createSelector(
  selectAuthTree,
  tree => tree.validate
)

export const selectValidateLoaded: Selector<*, *, boolean> = createSelector(
  selectValidateTree,
  tree => tree.loaded
)

export const selectValidateStatus: Selector<*, *, boolean> = createSelector(
  selectValidateTree,
  tree => tree.isValid
)
