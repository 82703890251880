// @flow
import React from "react";
import { css } from "aphrodite/no-important";
import Info from "components/Info";
import privacyPdf from "assets/Datenschutzhinweise Portal.pdf";

import styles from "./style";

export default function Footer(props): React$Node {
  return (
    <div style={props.onSmallPage && { marginTop: 200 }}>
      <Info />
      <div className={css(styles.footer)}>
        <div className="container">
          <p className={css(styles.text)}>
            Copyright ©2021 by OVB Vermögensberatung AG | All Rights Reserved |{" "}
            <a
              style={{ color: "#000" }}
              href="https://www.ovb.de/service-faq/impressum.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressum
            </a>{" "}
            |{" "}
            <a
              style={{ color: "#000" }}
              href={privacyPdf}
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutzhinweise
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
