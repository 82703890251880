import React from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { MOBILE } from 'constants/breakpoints'

import { Button } from "components";

import { Headline, Text } from "./Typo";
export class Teaser extends React.Component {
  render() {
    const {
      backgroundColor,
      link,
      button,
      headline,
      fontColor,
      text,
      preline,
      imageIcon,
      reversed,
      isOutlineButton,
      isFullWidth
    } = this.props.data;

    const color = fontColor;

    return (
      <div className={css(isFullWidth && styles.main)} style={isFullWidth ? { backgroundColor, color } : {}}>
        <div className="container">
          <div className={css(!isFullWidth && styles.main)} style={!isFullWidth ? { backgroundColor, color } : {}}>
          <div className="row">
            <div className="col-sm-4">
              <img
                src={imageIcon}
                className={css(styles.img)}
              />
            </div>
            <div className="col-sm-8" style={reversed ? { order: "-1" } : {}}>
              <Headline type="h6" style={{ color }}>
                {preline}
              </Headline>
              <Headline type="h2" style={{ color, margin: 0 }}>
                {headline}
              </Headline>
              <p style={{ text, color }} dangerouslySetInnerHTML={{ __html: text }} />
              <Button isOutline={isOutlineButton} to={link} isSmall>
                {button}
              </Button>
            </div>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    padding: "50px 12px",
    width: "100%",
    [MOBILE]: {
      padding: "25px 12px",
    },
  },
  preline: {
    textTransform: "initial",
  },
  img: {
    width: "100%",
    marginTop: 15,
    marginBottom: 15,
  },
});
