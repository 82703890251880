// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'
import { orderBy } from 'lodash'
import type { State as RootState } from '../types'
import type { State, Documents } from './types'

export const selectVideosTree = (state: RootState): State => state.videos

export const selectLoaded: Selector<*, *, boolean> = createSelector(
  selectVideosTree,
  tree => tree.loaded
)

export const selectVideos: Selector<*, *, Documents> = createSelector(
  selectVideosTree,
  ({ data, sort }): React$Node => {
    switch (sort) {
      case 'Name':
        return orderBy(data, [(o): React$Node => o.title.rendered], ['asc'])
      case 'Datum':
        return orderBy(data, [(o): React$Node => o.acf.datum], ['asc'])
      default:
        return data
    }
  }
)
