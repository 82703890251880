// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'
import { colors } from 'constants'

import unescape from 'utils/unescape'
import shape from 'assets/masthead/shape.png'

import type { Props } from '../types'

import styles from '../style'

const Complex = (props: Props) => (
  <div
    className={css(styles.main)}
    style={
      props.color
        ? {
            background: props.color,
            color: props.color === '#ffcc33' ? colors.primary : colors.white,
          }
        : { color: colors.white }
    }
  >
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <span className={css(styles.preline)}>{props.preline}</span>
          <h1 className={css(styles.headline)}>{unescape(props.headline)}</h1>
        </div>
      </div>
    </div>

    <img src={shape} alt="" className={css(styles.shape)} />
  </div>
)

export default Complex
