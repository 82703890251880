// @flow
import React from 'react'

export default function DOC({ className }) {
  return (
    <svg className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#013a65"
        d="M124.8 332.2h-11.9v53.4h11.9c5.8 0 10.5-2 13.9-5.9 3.4-4 5.2-9 5.2-15.2v-11.2c0-6.1-1.7-11.2-5.2-15.1-3.4-4-8-6-13.9-6zM199.6 386.4c5.6 0 10-2 13.2-5.9 3.2-3.9 4.8-8.9 4.8-15.1v-13.2c0-6.1-1.6-11.1-4.9-15-3.2-3.9-7.6-5.9-13.2-5.9-5.4 0-9.6 2-12.7 5.9-3.1 3.9-4.6 8.9-4.6 15v13.2c0 6.1 1.6 11.2 4.7 15.1 3.1 4 7.3 5.9 12.7 5.9z"
      />
      <path
        fill="#013a65"
        d="M458 118.2L346.5 8.4C345 6.9 342.9 6 340.7 6H135c-31.1 0-56.3 26.6-56.3 59.4v219.2H51.3v148.6h27.4v13.4c0 32.7 25.3 59.4 56.3 59.4h269.4c31.1 0 56.3-26.6 56.3-59.4V124.7c0-2.4-.9-4.8-2.7-6.5zM337.9 24.4l97 95.6h-52.8c-24.4 0-44.2-19.5-44.2-43.4V24.4zm105.8 422.2c0 22.9-17.6 41.5-39.3 41.5H135c-21.7 0-39.3-18.6-39.3-41.5v-13.4h187c36.5 0 66-33.3 66-74.3s-29.6-74.3-66-74.3h-187V65.4c0-22.9 17.6-41.5 39.3-41.5h185.9v52.7c0 33.8 27.4 61.3 61.2 61.3h61.6v308.7zm-343-51.2v-72.9h24.1c9.2 0 16.8 2.9 22.6 8.7 5.8 5.8 8.7 13.2 8.7 22.3v11.1c0 9.1-2.9 16.5-8.7 22.3-5.8 5.7-13.3 8.6-22.6 8.6h-24.1zm69.4-29.9v-13.1c0-8.9 2.7-16.3 8.2-22.1 5.5-5.9 12.6-8.8 21.3-8.8 8.9 0 16.2 2.9 21.8 8.8 5.6 5.9 8.4 13.3 8.4 22.1v13.1c0 8.9-2.8 16.3-8.4 22.2-5.6 5.9-12.9 8.8-21.8 8.8-8.7 0-15.8-2.9-21.3-8.8s-8.2-13.3-8.2-22.2zm90 15.3c2.9 3.9 6.9 5.9 12 5.9 5 0 8.8-1.3 11.3-3.9 2.6-2.6 3.9-6.4 3.9-11.3H299l.1.3c.1 7.3-2.3 13.3-7.2 17.8s-11.5 6.8-19.9 6.8c-8.5 0-15.4-2.9-20.6-8.6-5.3-5.7-7.9-13-7.9-22V352c0-8.9 2.6-16.2 7.8-22 5.2-5.8 12-8.6 20.4-8.6 8.6 0 15.3 2.2 20.3 6.6 4.9 4.4 7.3 10.4 7.2 18l-.1.3h-11.8c0-4.9-1.3-8.6-4-11.2-2.6-2.6-6.5-3.9-11.6-3.9-4.9 0-8.9 2-11.7 5.9-2.9 3.9-4.3 8.9-4.3 14.8v14c0 6 1.5 11 4.4 14.9z"
      />
    </svg>
  )
}
