// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'
import { MOBILE } from 'constants/breakpoints'

import dummy from 'assets/dummy.png'

export default StyleSheet.create({
  box: {
    position: 'relative',
    overflow: 'hidden',
    margin: '10px 0',
    color: colors.white,
    padding: '35px 25px',
    textAlign: 'left',
    flex: '1 1 33%',
    [MOBILE]: {
      overflow: 'auto',
    },
  },
  'box--module': {
    padding: '20px 25px',
  },
  'box--white': {
    background: '#fff',
  },
  'box--border': {
    marginRight: '5px',
    paddingBottom: 45,
    border: '2px solid rgb(0, 183, 229)',
  },
  'box--fullHeight': {
    height: '96%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  'box--nopad': {
    padding: '20px 0',
  },
  'box--related': {
    padding: 0,
    margin: 0,
  },
  'box--flex': {
    display: 'flex',
  },
  'box--lessTopPadding': {
    paddingTop: 15,
  },
  'box--lessBottomPadding': {
    paddingBottom: 15,
  },
  'box--fullHeight': {
    height: 'calc(100% - 10px)',
  },
  'box--flexColumn': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  'box--big': {
    color: colors.darkFont,
    paddingRight: 25,
    '@media (min-width: 576px)': {
      paddingLeft: 0,
      paddingRight: 'calc(33.33% + 25px)',
    },
  },
  'box--yellow': {
    color: colors.primary,
  },
  'box--overWidth': {
    width: 'calc(100% + 28px)',
    marginLeft: -14,
  },
  box__preline: {
    textTransform: 'initial',
  },
  box__headline: {
    color: colors.primary,
  },
  bottomBar: {
    marginTop: 24,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: -20,
    marginLeft: -25,
    padding: '0 25px',
    width: 'calc(100% + 50px)',
  },
  bottomBarText: {
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: 14,
    color: colors.darkFont,
    margin: 0,
    opacity: 1,
  },
  fullHeight: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '33.33%',
    height: '105%',
    backgroundImage: `url(${dummy})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'none',
    '@media (min-width: 576px)': {
      display: 'block',
    },
  },
  overlay: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(to left, transparent 77%, white 100%)',
  },
  eventHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [MOBILE]: {
      marginTop: 40,
    },
  },
})
