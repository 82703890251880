// @flow

import { CALL_API } from 'utils/callApi'

import {
  LOAD_ANSPRECHPARTNER_REQUEST,
  LOAD_ANSPRECHPARTNER_SUCCESS,
  LOAD_ANSPRECHPARTNER_FAILURE,
  LOAD_IMPORTANT_NEWS_REQUEST,
  LOAD_IMPORTANT_NEWS_SUCCESS,
  LOAD_IMPORTANT_NEWS_FAILURE,
  LOAD_NEWS_REQUEST,
  LOAD_NEWS_SUCCESS,
  LOAD_NEWS_FAILURE,
  LOAD_NEWSBANNER_REQUEST,
  LOAD_NEWSBANNER_SUCCESS,
  LOAD_NEWSBANNER_FAILURE,
  LOAD_ARTICLE_REQUEST,
  LOAD_ARTICLE_SUCCESS,
  LOAD_ARTICLE_FAILURE,
} from './actionTypes'

export function loadImportantNews(): Object {
  return {
    [CALL_API]: {
      endpoint: '/acf/v3/options/options',
      types: [
        LOAD_IMPORTANT_NEWS_REQUEST,
        LOAD_IMPORTANT_NEWS_SUCCESS,
        LOAD_IMPORTANT_NEWS_FAILURE,
      ],
    },
  }
}

export function loadNews(): Object {
  return {
    [CALL_API]: {
      endpoint: '/wp/v2/posts',
      types: [LOAD_NEWS_REQUEST, LOAD_NEWS_SUCCESS, LOAD_NEWS_FAILURE],
    },
  }
}

export function loadNewsbanner(): Object {
  return {
    [CALL_API]: {
      endpoint: '/acf/v3/options/options',
      types: [LOAD_NEWSBANNER_REQUEST, LOAD_NEWSBANNER_SUCCESS, LOAD_NEWSBANNER_FAILURE],
    },
  }
}

export function loadAnsprechpartnerOptions(): Object {
  return {
    [CALL_API]: {
      endpoint: '/acf/v3/options/options',
      types: [
        LOAD_ANSPRECHPARTNER_REQUEST,
        LOAD_ANSPRECHPARTNER_SUCCESS,
        LOAD_ANSPRECHPARTNER_FAILURE,
      ],
    },
  }
}

export function fetchArticle(slug: string): Object {
  return {
    [CALL_API]: {
      endpoint: `/wp/v2/posts?slug=${slug}`,
      types: [LOAD_ARTICLE_REQUEST, LOAD_ARTICLE_SUCCESS, LOAD_ARTICLE_FAILURE],
    },
  }
}
