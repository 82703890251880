import React from "react";
import { StyleSheet, css } from "aphrodite/no-important";

import { MOBILE } from 'constants/breakpoints'

import Video from "components/Video";

import { Headline, Text } from "./Typo";

const isVideo = (url) => {
  if (url.indexOf("mp4") >= 0) {
    return true;
  }

  if (url.indexOf("vimeo") >= 0) {
    return true;
  }

  return false;
};

export class ContentBlock extends React.Component {
  render() {
    const { reversed, preline, headline, videoimage, text, embeddedVideoCode } = this.props.data;

    return (
      <div className={css(styles.main)}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              {embeddedVideoCode && <div dangerouslySetInnerHTML={{ __html: embeddedVideoCode }} />}
              {!embeddedVideoCode && isVideo(videoimage) && <Video videoUrl={videoimage} />}
              {!embeddedVideoCode && !isVideo(videoimage) && <img src={videoimage} />}
            </div>
            <div className="col-sm-6" style={reversed ? { order: "-1" } : {}}>
              <h3 className={css(styles.preline)}>{preline}</h3>
              <Headline type="h2" style={{ margin: 0 }}>
                {headline}
              </Headline>
              <Text>{text}</Text>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  main: {
    padding: "50px 12px",
    width: "100%",
    [MOBILE]: {
      padding: "25px 12px",
    },
  },
  preline: {
    textTransform: "initial",
    fontSize: "16px",
    color: "rgb(0, 51, 102)",
  },
  img: {
    width: "100%",
  },
});
