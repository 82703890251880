// @flow
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { css } from 'aphrodite/no-important'
import styles from '../style'

class LinkListItem extends Component {
  state = { read: false }

  componentDidMount() {
    if (localStorage.getItem(`news-${this.props.dataIndex}`) === 'true') {
      this.setState({ read: true })
    }
  }

  handleStorage = () => {
    if (localStorage.getItem(`news-${this.props.dataIndex}`) === 'false') {
      localStorage.setItem(`news-${this.props.dataIndex}`, 'true')
      this.setState({ read: true })
    }
  }

  render(): React$Node {
    const { dataIndex } = this.props
    const { read } = this.state

    return (
      <Link
        className={css(styles.textlink, read && styles.textlinkRead)}
        to={`blog/${this.props.data.slug}`}
        onClick={() => this.handleStorage(dataIndex)}
      >
        {this.props.data.title.rendered}
        <span className={css(styles.read)}>{read ? '✔ Gelesen' : '✗ Ungelesen'}</span>
      </Link>
    )
  }
}

export default LinkListItem
