import React from "react";
import { Button } from "components";
import Slider from "react-slick";
import { css, StyleSheet } from "aphrodite/no-important";
import { MOBILE } from 'constants/breakpoints'

import { Text } from "./Typo";

export class UserStories extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      className: "storie-slider",
      responsive: [
        {
          breakpoint: 426,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const { block, headline } = this.props.data;
    return (
      <div className={css(styles.wrapper)}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className={css(styles.headline)}>{headline}</h2>
            </div>
            <Slider {...settings} ref={this.slider}>
              {block.map((item) => (
                <Storie data={item} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

class Storie extends React.Component {
  render() {
    const {
      img,
      text,
      buttonLink,
      buttonText,
      background,
      name,
      funktion,
      isOutlineButton,
    } = this.props.data;
    return (
      <div className={css(styles.block)}>
        <div
          className={css(styles.img)}
          style={{
            backgroundImage: `url(${img})`,
          }}
        />
        <div className={css(styles.name)}>{name}</div>
        <div className={css(styles.funktion)}>{funktion}</div>
        <Text>{text}</Text>
        <Button isOutline={isOutlineButton} to={buttonLink} isSmall>
          {buttonText}
        </Button>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    margin: "50px 12px",
    [MOBILE]: {
      padding: "25px 12px",
    },
  },
  block: {
    background: "#f2f2f2",
    padding: 24,
    marginTop: 5,
    marginBottom: 5,
  },
  img: {
    width: 100,
    height: 100,
    borderRadius: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  name: {
    color: "#003A65",
    fontWeight: 600,
    fontSize: 18,
    marginTop: 10,
    marginBottom: 0,
  },
  funktion: {
    color: "#000",
    fontSize: 14,
  },
  text: {
    color: "#000",
    fontSize: 16,
    margin: "10px 0",
  },
  headline: {
    margin: "50px 0",
    textAlign: "center",
    [MOBILE]: {
      marginTop: 0,
    },
  },
});
