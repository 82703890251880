export const isMobile = () =>
  /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
    window.navigator.userAgent || window.navigator.vendor || window.opera
  )

export const isInternetExplorer = () =>
  /MSIE/.test(window.navigator.userAgent) || /Trident/.test(window.navigator.userAgent)

export const escapeICSDescription = description => description.replace(/(\r?\n|<br ?\/?>)/g, '\\n')

export const convertTime12to24 = time12h => {
  const [time, modifier] = time12h.split(' ')

  let [hours, minutes] = time.split(':')

  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'pm') {
    hours = parseInt(hours, 10) + 12
  }

  return `${hours}:${minutes}`
}

export const buildShareFile = ({
  description = '',
  ctz = '',
  endDatetime,
  location = '',
  startDatetime,
  timezone = '',
  title = '',
  url = '',
}) => {
  const content = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${url}`,
    'METHOD:PUBLISH',
    timezone === '' ? `DTSTART:${startDatetime}` : `DTSTART;TZID=${timezone}:${startDatetime}`,
    timezone === '' ? `DTEND:${endDatetime}` : `DTEND;TZID=${timezone}:${endDatetime}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${escapeICSDescription(description)}`,
    `LOCATION:${location}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n')

  console.log(content)

  return isMobile() ? encodeURI(`data:text/calendar;charset=utf8,${content}`) : content
}

export const downloadFile = item => {
  const date = item.acf.datum
  const time = item.acf.start
  const convertedTime = convertTime12to24(item.acf.start)

  const checkShort = () => {
    if (convertedTime.split(':')[0].length === 1) {
      return `0${convertedTime.split(':')[0]}`
    }
    return convertedTime.split(':')[0]
  }

  const startDatetime = `${date.split('-')[0]}${date.split('-')[1]}${
    date.split('-')[2]
  }T${checkShort()}${convertedTime.split(':')[1]}00`

  const data = {
    BEGIN: 'VTIMEZONE',
    description: item.title.rendered,
    duration: 2,
    endDatetime: '',
    location: 'online',
    startDatetime,
    title: item.title.rendered,
    url: item.acf.link,
  }

  const filename = item.title.rendered
  const url = buildShareFile(data)
  if (url.startsWith('BEGIN')) {
    const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' })

    if (isInternetExplorer()) {
      window.navigator.msSaveOrOpenBlob(blob, `${filename}.ics`)
    } else {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.setAttribute('download', `${filename}.ics`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } else {
    window.open(url, '_blank')
  }
}
