// @flow

import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'
import { connect } from 'react-redux'

import { selectCurrentDate } from 'modules/calendar/date/selectors'
import {
  selectEvents,
  selectEventPageLoading,
  selectEventsForDate,
} from 'modules/calendar/events/selectors'
import { loadEventsPage, loadEvents } from 'modules/calendar/events/actions'
import { getBeginNext, getBeginPrevious } from 'react-calendar/dist/shared/dates'

import Calendar from 'components/Calendar'
import Event from 'components/Event'
import Button from 'components/Button'
import EventsTable from 'components/Events/Table'
import Headline from 'components/Box/Headline'
import boxStyles from 'components/Box/style'
import { convertTime } from '../EventWeek/helper'
import type { Props } from './types'

class EventCalendar extends Component<Props> {
  calendar = React.createRef()

  state = {
    month: !this.calendar.current
      ? moment(new Date())
          .locale('de')
          .format('MMMM')
      : moment(this.calendar.current.state.activeStartDate)
          .locale('de')
          .format('MMMM'),
  }

  componentDidMount = () => this.props.loadEvents()

  componentWillReceiveProps = newProps => {
    if (newProps.allEvents.length !== 0) {
      const lengthStr = newProps.allEvents.length.toString()
      const val = lengthStr.slice(-2)
      const num = lengthStr.slice(0, 1)
      if (val === '00' && !newProps.pageLoading) this.props.loadEventsPage(parseInt(num) + 1)
    }
  }

  updateState = () => {
    setTimeout(() => {
      this.setState({
        month: moment(this.calendar.current.state.activeStartDate)
          .locale('de')
          .format('MMMM'),
      })
    }, 50)
  }

  handleCalendarControl = (): React$Node => {
    if (!this.calendar.current) return null

    return (
      <div className="react-calendar__navigation">
        <button
          style={{ margin: 0, padding: 0 }}
          className="react-calendar__navigation__arrow react-calendar__navigation__prev-button"
          type="button"
          onClick={() => {
            this.updateState()
            const { activeStartDate, view } = this.calendar.current.state
            this.calendar.current.setActiveStartDate(getBeginPrevious(view, activeStartDate))
          }}
        >
          ‹
        </button>
        <button
          style={{ margin: '5px 10px 0px', padding: 0 }}
          className="react-calendar__navigation__label"
          type="button"
        >
          {this.state.month}
        </button>
        <button
          style={{ margin: 0, padding: 0 }}
          className="react-calendar__navigation__arrow react-calendar__navigation__next-button"
          type="button"
          onClick={() => {
            this.updateState()
            const { activeStartDate, view } = this.calendar.current.state
            this.calendar.current.setActiveStartDate(getBeginNext(view, activeStartDate))
          }}
        >
          ›
        </button>
      </div>
    )
  }

  render(): React$Node {
    const { events } = this.props

    return (
      <div
        className={`${css(boxStyles.box, boxStyles['box--module'], boxStyles['box--nopad'])} gap`}
      >
        <div className="row">
          <div
            className={[
              this.props.isSmall ? 'col-lg-4 ' : 'col-lg-4 col-md-12',
              css(boxStyles.fullHeight),
            ]
              .filter(n => n)
              .join(' ')}
          >
            {!this.props.isMasthead && (
              <Headline style={{ width: 280 }} headline="Eventkalender">
                {this.props.isSmall || '2018'}
                {this.props.isSmall && this.handleCalendarControl()}
              </Headline>
            )}
            <Calendar
              withRef={this.calendar}
              events={this.props.allEvents}
              date={this.props.date}
              isSmall={this.props.isSmall}
            />
            {!this.props.isDetailPage && (
              <div style={{ marginTop: 55 }}>
                <Button to="/veranstaltungen">Zu unseren Veranstaltungen</Button>
              </div>
            )}
          </div>
          <div
            className={[
              this.props.isSmall ? 'col-lg-4 ' : 'col-lg-8 col-md-12',
              css(boxStyles.eventHolder),
            ]
              .filter(n => n)
              .join(' ')}
          >
            <div>
              {!this.props.isMasthead && (
                <Headline headline="Events">{this.props.date.locale('de').format('LL')}</Headline>
              )}
              {this.props.isMasthead && (
                <EventsTable
                  headline="Wichtige Events"
                  subline={this.props.date.locale('de').format('LL')}
                  isMasthead
                  data={events.sort((a, b) => {
                    if (a.acf.start && b.acf.start) {
                      const aStart = convertTime(a.acf.start)
                      const bStart = convertTime(b.acf.start)
                      return aStart.localeCompare(bStart)
                    }
                    return 0
                  })}
                />
              )}
              {!this.props.isMasthead &&
                events.map((item): React$Node => <Event key={item.id} data={item} />)}
              {this.props.isDetailPage && events.length === 0 && <Event hasSpacingLeft disabled />}
              {!this.props.isMasthead && events.length === 0 && <Event disabled />}
            </div>
          </div>
          {this.props.isSmall && (
            <div className="col-md-4">
              <Headline headline="Facebook Feed" />
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fovbdeutschland%2F&tabs=timeline&width=320&height=400&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
                width="auto"
                height="400"
                style={{ width: '100%', border: 'none', overflow: 'hidden' }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  date: selectCurrentDate(state),
  allEvents: selectEvents(state),
  pageLoading: selectEventPageLoading(state),
  events: selectEventsForDate(state),
})

const mapActionsToProps = {
  loadEventsPage,
  loadEvents,
}

export default connect(mapStateToProps, mapActionsToProps)(EventCalendar)
