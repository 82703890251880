// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'
import Dropdown from 'components/Dropdown'
import history from 'modules/history'

import bereiche from 'constants/bereiche'

import styles from './style'
import type { Props, State } from './types'

export default class Search extends Component<Props, State> { // eslint-disable-line
  input: React$Ref<'input'> = React.createRef()

  state = {
    value: '',
  }

  componentWillReceiveProps(state) {
    if (state.active) {
      this.input.current.focus()
    } else {
      this.input.current.blur()
    }
  }

  handleSubmit = e => {
    // if (this.state.filter !== 'Alle Bereiche') {
    //   history.push('/search', {
    //     value: `${e.search}&tax_query[taxonomy]=veranstaltungs_kategorien&tax_query[terms]=${
    //       this.state.filter
    //     }&tax_query[field]=slug`,
    //   })
    // }
    e.preventDefault()

    this.setState({ value: '' })

    history.push('/search', { value: this.state.value })
  }

  render(): React$Node {
    return (
      <form
        onSubmit={this.handleSubmit}
        className={css(styles.main, this.props.active && styles.mainActive)}
      >
        <Dropdown currentValue="Alle Bereiche" values={bereiche} />
        <input
          ref={this.input}
          onChange={evt => this.setState({ value: evt.target.value })}
          value={this.state.value}
          placeholder="Suchen Sie nach Themen, Dokumenten, etc."
          type="text"
          className={css(styles.mainInput)}
        />
      </form>
    )
  }
}
