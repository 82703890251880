// @flow
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux/lib/reducer'
import type { CombinedReducer } from 'redux'
import { reducer as formReducer } from 'redux-form'

import { reducer as newsReducer } from './news'
import { reducer as calendarReducer } from './calendar'
import { reducer as menuReducer } from './menu'
import { reducer as documentsReducer } from './documents'
import { reducer as contactsReducer } from './contact'
import { reducer as uiReducer } from './ui'
import { reducer as searchReducer } from './search'
import { reducer as authReducer } from './auth'
import { reducer as productsReducer } from './products'
import { reducer as videosReducer } from './videos'
import { reducer as taxonomyReducer } from './taxonomy'
import { reducer as pageReducer } from './page'
import { reducer as linksReducer } from './importantLinks'
import { reducer as partnersReducer } from './partners'
import type { Action } from './types'

export const rootReducer: CombinedReducer<any, Action> = combineReducers({
  router: routerReducer,
  news: newsReducer,
  calendar: calendarReducer,
  contacts: contactsReducer,
  documents: documentsReducer,
  menu: menuReducer,
  ui: uiReducer,
  auth: authReducer,
  form: formReducer,
  search: searchReducer,
  products: productsReducer,
  videos: videosReducer,
  taxonomy: taxonomyReducer,
  page: pageReducer,
  links: linksReducer,
  partners: partnersReducer,
})
