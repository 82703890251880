// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  main: {
    paddingTop: 50,
  },
  mainHeadline: {
    marginTop: 0,
  },
  emptyState: {
    padding: '40px 0',
    textAlign: 'center',
  },
  bar: {
    marginTop: 40,
    marginBottom: 45,
  },
  mainHolder: {
    padding: '100px 0 55px',
  },
  textlink: {
    fontSize: 16,
    color: '#2771C6',
    lineHeight: '34px',
    display: 'block',
  },
  videoHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  headline: {
    marginBottom: 30,
  },
})
