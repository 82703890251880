// @flow
import React from 'react'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'

import { increaseFontSize, decreaseFontSize } from 'modules/ui/font/actions'
import { selectFontSize } from 'modules/ui/font/selectors'
import plus from 'assets/fontcontrol/plus.png'
import minus from 'assets/fontcontrol/minus.png'

import styles from './style'

import type { Props } from './types'

function FontControl(props: Props): React$Element<'div'> {
  return (
    <div className={css(styles.main)}>
      <div
        className={css(styles.btn, styles.btnFirst)}
        role="button"
        tabIndex="0"
        onClick={() => props.increaseFontSize()}
      >
        <img className={css(styles.img)} src={plus} alt="" />
      </div>
      <div
        className={css(styles.btn, styles.btnSecond)}
        role="button"
        tabIndex="0"
        onClick={() => props.decreaseFontSize()}
      >
        <img className={css(styles.img)} src={minus} alt="" />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({ size: selectFontSize(state) })
export default connect(
  mapStateToProps,
  { increaseFontSize, decreaseFontSize }
)(FontControl)
