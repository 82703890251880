// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  main: {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms ease',
  },
  icon: {
    cursor: 'pointer',
    outline: 'none',
    width: 72,
    height: 72,
  },
  video: {
    width: '100%',
    flex: 1,
  },
  'overlay--disabled': {
    opacity: 0,
    pointerEvents: 'none',
    transform: 'scale(0.1)',
  },

  calendar__item: {
    overflow: 'visible !important',
    position: 'relative',
  },
  calendar__dots: {
    position: 'absolute',
    bottom: -10,
  },
  calendar__dot: {
    background: '#00bede',
    display: 'inline-block',
    width: 4,
    height: 4,
    borderRadius: 3,
    margin: '0 2px',
  },
})
