// @flow

import { CALL_API } from 'utils/callApi'
import { FETCH_TOKEN_REQUEST, FETCH_TOKEN_SUCCESS, FETCH_TOKEN_FAILURE } from './actionTypes'

export default function fetchUserToken(username, password): * {
  return {
    [CALL_API]: {
      endpoint: '/jwt-auth/v1/token',
      types: [FETCH_TOKEN_REQUEST, FETCH_TOKEN_SUCCESS, FETCH_TOKEN_FAILURE],
      options: {
        method: 'POST',
        body: { username, password },
      },
    },
  }
}
