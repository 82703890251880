// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'
import type { Props } from './types'

export default function Label(props: Props): React$Element<'div'> {
  return <div className={css(styles.label)}>{props.children}</div>
}
