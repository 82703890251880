import { StyleSheet } from "aphrodite/no-important";
import { colors } from "constants";

import { lighten } from "polished";

export default StyleSheet.create({
  button: {
    display: "inline-block",
    fontWeight: 300,
    fontSize: 14,
    lineHeight: "24px",
    padding: "10px 16px",
    outline: "none",
    cursor: "pointer",
  },
  "button--primary": {
    color: colors.white,
    background: colors.primary,
    ":hover": {
      color: colors.white,
      background: lighten(0.1, colors.primary),
    },
  },
  "button--outline": {
    color: colors.primary,
    background: colors.white,
    border: "3px solid red",
    borderColor: colors.primary,
    ":hover": {
      color: colors.primary,
      background: lighten(0.1, colors.white),
    },
  },
  "button--important": {
    color: "#fff !important",
  },

  "button--block": {
    display: "block",
    textAlign: "center",
  },
  "button--small": {
    padding: "8px 16px",
  },
});
