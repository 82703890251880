// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { primaryGlary, grey1, grey6 } from 'constants/color'

export default StyleSheet.create({
  pagination: {
    fontSize: 12,
    display: 'flex',
    margin: '5px 0',
  },

  pagination__container: {
    padding: 0,
    margin: '0 10px',
    listStyle: 'none',
    display: 'flex',
    // backgroundColor: 'red',
  },

  pagination__item: {
    // backgroundColor: 'blue',
    color: primaryGlary,
    padding: 4,
  },

  'pagination__item--active': {
    // backgroundColor: 'green',
    color: '#3D424F !important',
    textDecoration: 'underline',
  },

  pagination__selectContainer: {
    display: 'inline-block',
    margin: '0 2px',
    position: 'relative',

    ':after': {
      position: 'absolute',
      top: '50%',
      marginTop: -2,

      right: 6,

      content: '""',
      width: 0,
      height: 0,
      border: `4px solid transparent`,
      borderTopColor: primaryGlary,
      display: 'block',
    },
  },

  pagination__select: {
    fontFamily: 'inherit',
    borderRadius: 3,
    textAlign: 'right',
    border: `1px solid ${grey1}`,
    height: 22,
    padding: '0 18px 0 6px',
    appearance: 'none',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    backgroundColor: 'white',

    fontSize: 12,
    color: grey6,
  },
})
