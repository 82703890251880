// @flow

export const dark: string = '#003a68'
export const darkFont: string = '#000'
export const white: string = '#fff'
export const primary: string = '#003A65'
export const primaryLight: string = '#2771C6'
export const primaryGlary: string = '#1F5FFF'
export const highlight: string = '#CC0066'
export const blue: string = '#00B7E5'
export const green: string = '#026666'

export const grey0: string = '#F6F8FA'
export const grey1: string = '#DEE2E7'
export const grey6: string = '#3D424F'
