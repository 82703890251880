import { StyleSheet } from "aphrodite/no-important";
const sideSpace = 40;
const itemHeight = 60;
const center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default StyleSheet.create({
  menu: {
    display: "flex",
    flexDirection: "column",
    zIndex: 100,
    maxWidth: "400px",
    background: "#fff",
    color: "#000",
    position: "fixed",
    top: 0,
    right: 0,
    width: "100vw",
    height: "100vh",
    transform: "translateX(400px)",
    transition: "all 300ms ease",
  },
  menuBackground: {
    zIndex: 99,
    opacity: 0.5,
    maxWidth: "100vw",
    background: "#000",
    position: "fixed",
    top: 0,
    right: 0,
    width: "100vw",
    height: "100vh",
    transform: "translateX(100vw)",
    transition: "all 300ms ease",
  },
  menuBackgroundActive: {
    transform: "translateX(0)",
  },
  menuActive: {
    transform: "translateX(0)",
  },
  menuTop: {
    background: "#003366",
    padding: `33px ${sideSpace}px`,
  },
  menuTopLeft: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: "500",
  },
  menuTopRight: {},
  menuSearchWrapper: {
    ...center,
    background: "#d1d5db",
    padding: `12px ${sideSpace}px`,
    position: "relative",
  },
  menuSearch: {
    height: 42,
    width: "100%",
    padding: "9px 13px",
    paddingLeft: 40,
    background: "#FFFFFF",
    border: "1px solid #D1D5DB",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: 6,
    fontWeight: 400,
    fontSize: 16,
    color: "#6B7280",
    position: "relative",
  },
  menuSearchImage: {
    position: "absolute",
    left: 55,
    width: 16,
    height: 16,
    top: 25,
    zIndex: 1,
  },
  menuContent: {
    background: "#fff",
    maxHeight: "100%",
    flex: 2,
    overflow: "auto",
    padding: `12px 0px`,
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: itemHeight,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#003366",
    cursor: "pointer"
  },
  menuSubWrapper: {
    maxHeight: 0,
    overflow: "hidden",
  },
  menuSubWrapperActive: {
    // paddingLeft: 30,
    maxHeight: "auto",
    overflow: "hidden",
  },
  menuBottom: {
    ...center,
    alignItems: "baseline",
    background: "#fff",
    width: "100%",
    flexDirection: "column",
    padding: `32px ${sideSpace}px`,
  },
  menuItemLight: {
    color: "#fff",
  },
  menuItemPadding: {
    paddingLeft: sideSpace,
    paddingRight: sideSpace,
  },
  menuItemSecondPadding: {
    paddingLeft: sideSpace * 2,
    paddingRight: sideSpace,
  },
  menuItemThirdPadding: {
    paddingLeft: sideSpace * 2,
    paddingRight: sideSpace,
  },
  caret: {
    position: "relative",
    transition: "all 300ms ease",
    transform: "rotate(0) scale(2)",
    width: 24,
    height: 24,

  },
  caretActive: {
    transform: "rotate(180deg) scale(2)",
  },
  badge: {
    background: "#f4f4f4",
    color: "#000",
    padding: 5,
    fontSize: 12,
    margin: "5px 0",
    width: "max-content",
  },
  badgeHolder: {
    padding: "0 40px",
    display: "flex",
    flexDirection: "column",
  },
  blueBackground: {
    background: "rgb(0, 51, 102)",
    color: "#fff"
  },
  menuItemWhite: {
    color: "#fff",
  },
  menuItemThird: {
    ":hover": {
      background: "rgb(0, 51, 102) !important",
      color: "#fff !important"
    }
  }
});
