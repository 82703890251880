// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { colors } from 'constants'

export default StyleSheet.create({
  textlink: {
    fontWeight: 500,
    fontSize: 14,
    display: 'flex',
    cursor: 'pointer',
    borderBottom: `1px solid #DCE4EA`,
    paddingLeft: 44,
    color: colors.primary,
    paddingTop: 9,
    paddingBottom: 9,
    background: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: '34px',
    paddingRight: 15,
    position: 'relative',
    ':last-of-type': {
      border: 'none',
    },
    ':before': {
      content: "'ᐳ'",
      zIndex: 1,
      position: 'absolute',
      color: '#fff',
      left: 11,
      top: 0,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      transition: 'left 300ms ease',
    },
    ':hover': {
      background: 'none',
      color: colors.primary,
      opacity: 0.8,
    },
    ':hover:before': {
      left: 10,
    },
    ':after': {
      width: 32,
      height: 32,
      backgroundColor: 'rgba(0, 58, 101, 1)',
      content: "''",
      position: 'absolute',
      left: 0,
      top: 11,
    },
  },
  read: {
    position: 'absolute',
    opacity: 1,
    right: 0,
  },
})
