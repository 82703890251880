// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import debug from 'debug'
import Helmet from 'react-helmet'
import { css } from 'aphrodite/no-important'

import bereiche from 'constants/bereiche'
import { Footer, ArticlePreview } from 'components'
import { selectLoaded, selectSearchData } from 'modules/search/selectors'
import { selectDocumentsPages } from 'modules/documents/selectors'
import { startSearch } from 'modules/search/actions'
import { loadDocumentsByPage } from 'modules/documents/actions'
import SearchBar from 'components/Form/SearchBar'
import Spin from 'components/Spin'

import styles from './style'
import type { Props, State } from './types'

const log = debug('pages:News')

class Search extends Component<Props, State> {
  state = { currentValue: false, searchValue: '', filterValue: '', extendedValue: false }

  componentDidMount = () => {
    if (this.props.history.location.state) {
      this.setState({
        searchValue: this.props.history.location.state.value,
        filterValue: this.props.history.location.state.filter,
      })
      this.props.startSearch(this.props.history.location.state.value)

      this.props.loadDocumentsByPage(1, 100, {
        orderby: 'date',
        order: 'desc',
        q: this.props.history.location.state.value,
        media_category: null,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      typeof this.props.location.state === 'object' &&
      typeof nextProps.location.state === 'object'
    ) {
      if (this.props.location.state.value !== nextProps.location.state.value) {
        this.setState({ searchValue: nextProps.location.state.value })
      }
      if (this.props.location.state.filter !== nextProps.location.state.filter) {
        this.setState({ filterValue: nextProps.location.state.filter })
      }
    }
  }

  submit = (input, dropDown, filter) => {
    if (typeof input === 'undefined') return null
    this.setState({ searchValue: input })
    this.props.startSearch(input, dropDown.value, filter.value)

    this.props.loadDocumentsByPage(1, 100, {
      orderby: 'date',
      order: 'desc',
      q: input,
      media_category: null,
    })
  }

  onFilterChange = val => {
    this.setState({ currentValue: val })
  }

  onExtendedFilterChange = val => {
    this.setState({ extendedValue: val })
  }

  handleFilter = val => {
    this.setState({ currentValue: val })
  }

  render(): React$Element<'div'> {
    const value = this.state.searchValue
    let { search } = this.props

    if (this.state.currentValue && typeof this.state.currentValue === 'object') {
      search = search.filter(item => item.postType === this.state.currentValue.value)
    }

    let combinedData = []
    if (this.props.documents[1] && search) {
      const combined = [...search, ...this.props.documents[1]]
      combinedData = combined.sort((a, b) => new Date(b.postDate) - new Date(a.postDate))
    }

    if (this.state.currentValue.value) {
      combinedData = combinedData.filter(v => v.postType === this.state.currentValue.value)
    }

    if (this.state.extendedValue.value) {
      const splitFilter = this.state.extendedValue.value.split(',')
      const type = splitFilter[0]
      const dir = splitFilter[1]

      combinedData = combinedData.sort((a, b) => a[type].localeCompare(b[type]))

      if (dir === 'DESC') {
        combinedData = combinedData.reverse()
      }
    }

    return (
      <div className={css(styles.main)}>
        <Helmet title={`Suchergebnisse für ${value}`} />

        <div className="container">
          <div className={css(styles.mainHolder)}>
            {value && <h2>Suchergebnisse für {value}</h2>}
            {!value && <h2>Bitte geben Sie ein Suchbegriff ein</h2>}
            <div className={css(styles.bar)}>
              <SearchBar
                placeholder="Suchen Sie nach Themen, Dokumenten, etc."
                dropDown={{ values: bereiche }}
                // onChange={this.onChange}
                initialValue={value}
                initialFilterValue={this.state.filterValue}
                onFilterChange={this.onFilterChange}
                showExtendedFilter
                onSubmit={this.submit}
                onExtendedFilterChange={this.onExtendedFilterChange}
              />
            </div>
            {this.props.loaded ? (
              <div className="row">
                {combinedData &&
                  combinedData.map((item, index): React$Node => {
                    if (
                      item.postType === 'videos' ||
                      item.postType === 'accordions' ||
                      item.postType === 'partner' ||
                      item.postType === 'veranstaltungen' ||
                      item.postType === 'wichtige_links'
                    ) {
                      return null
                    }
                    return (
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <ArticlePreview
                          isList
                          key={item.iD}
                          isProduct={item.type === 'product'}
                          data={item}
                        />
                      </div>
                    )
                  })}
              </div>
            ) : (
              <Spin x2 />
            )}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default connect(
  state => ({
    loaded: selectLoaded(state),
    search: selectSearchData(state),
    documents: selectDocumentsPages(state),
    form: state.form,
  }),
  { startSearch, loadDocumentsByPage }
)(Search)
