// @flow

import { LOAD_PAGE_REQUEST, LOAD_PAGE_SUCCESS, LOAD_PAGE_FAILURE } from './actionTypes'

import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  data: [],
}

export const reducer = (state: State = initialState, { type, response }: Action): State => {
  switch (type) {
    case "CLEAR_PAGE":
      return { data: [], loaded: false }
    case LOAD_PAGE_REQUEST:
      return { ...state, loaded: false }

    case LOAD_PAGE_SUCCESS:
      return { ...state, loaded: true, data: response }

    case LOAD_PAGE_FAILURE:
      return initialState

    default:
      return state
  }
}
