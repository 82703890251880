// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { css } from 'aphrodite/no-important'

import { Footer, Excerpt, PDFDownload } from 'components'

import { loadVideos, setSort } from 'modules/videos/actions'
import { selectLoaded, selectVideos } from 'modules/videos/selectors'

import VideoSlider from 'components/_modules/VideoSlider'

import styles from './style'
import type { Props, State } from './types'

class Videos extends Component<Props, State> {
  // eslint-disable-next-line react/no-unused-state
  state = { loading: false, currentValue: 'Sortieren' }

  componentDidMount = () => this.props.loadVideos()

  switchLoading = () => {
    this.setState(state => ({ loading: !state.loading }))
  }

  handleSort = val => {
    this.setState({ currentValue: val })
    this.props.setSort(val)
  }

  renderExcerpt = item => (
    <div className="col-lg-4 col-md-6 col-sm-12">
      <Excerpt
        media={{
          type: 'video',
          image: 'https://placehold.it/640x480',
          video: item.acf.video.url,
        }}
        author="OVB"
        date={new Date(item.date)}
        title={item.title.rendered}
        margin
        Footer={
          <>
            <PDFDownload link={item.acf.pdf}>PDF</PDFDownload>
            <PDFDownload
              noIcon
              link={item.acf.video.url}
              style={{ marginTop: 5, paddingTop: 10, paddingBottom: 10 }}
            >
              Zum Download
            </PDFDownload>
          </>
        }
      >
        {item.acf.kurzbeschreibung}
      </Excerpt>
    </div>
  )

  render(): React$Node {
    const { videos } = this.props

    return (
      <div className={css(styles.main)}>
        <Helmet title="Home" />
        <div className="container">
          <div className="row" style={{ marginBottom: 50 }}>
            <VideoSlider />
          </div>
          <div className="row">{videos && videos.map(item => this.renderExcerpt(item))}</div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default connect(
  state => ({
    loaded: selectLoaded(state),
    videos: selectVideos(state),
  }),
  { loadVideos, setSort }
)(Videos)
