// @flow
import React, { Component } from 'react'
import { css } from 'aphrodite/no-important'
import styles from './style'
import type { Props, State } from './types'

import { SORT, FILTER } from './data'

export default class Sidebar extends Component<Props, State> {
  static defaultProps = {
    onChange: () => {},
    type: 'sort',
  }

  state = { active: 0, expanded: false, value: [] }

  changeSort = (value: *, index: number | null) => {
    this.props.onChange(value)
    this.setState({ active: index, value })

    if (value === []) {
      this.props.onChange(value)
      this.setState({ value: [] })
    }
  }

  toggleExpand = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }))
  }

  render(): React$Node {
    const DATA = this.props.type === 'sort' ? SORT : FILTER
    const { expanded } = this.state

    return (
      <div className={css(styles.sidebar)} onClick={this.toggleExpand}>
        <h4 className={css(styles.headline, expanded && styles.headlineActive)}>
          {this.props.headline}
          {this.props.type === 'filter' && (
            <div
              className={css(styles.reset, !this.state.value.name && styles.resetActive)}
              role="button"
              tabIndex="0"
              onClick={() => this.changeSort([], [])}
            >
              Reset filters
            </div>
          )}
        </h4>
        <div className={css(styles.content, expanded && styles.contentActive)}>
          {DATA.map((item, index) => (
            <label
              className={css(
                styles.sidebarItem,
                this.state.active === index && styles.sidebarItemActive
              )}
              key={JSON.stringify(index)}
              role="button" // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
              tabIndex="0"
              htmlFor={item.name}
              onClick={() => this.changeSort(item, index)}
            >
              <input
                className={css(styles.checkbox)}
                type="checkbox"
                id={item}
                checked={this.state.active === index}
              />
              {item.name}
            </label>
          ))}
        </div>
      </div>
    )
  }
}
