// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { css } from 'aphrodite/no-important'

import { Footer, Box, Facebook } from 'components'
import { loadNews, loadNewsbanner, loadImportantNews } from 'modules/news/actions'
import { selectPage } from 'modules/page/selectors'
import { fetchPage } from 'modules/page/actions'
import {
  selectNewsData,
  selectNewsbanner,
  selectArticleOfTheWeek,
  selectHighligtedArticle,
  selectImportantArticle,
  selectImportantTree,
} from 'modules/news/selectors'
import { selectCurrentDate } from 'modules/calendar/date/selectors'
import { selectEventsForDate } from 'modules/calendar/events/selectors'
import Documents from 'components/_modules/Documents'
import Linklist from 'components/_modules/Linklist'
import Banner from 'components/_modules/Banner'
import EventWeek from 'components/_modules/EventWeek'
import VideoSlider from 'components/_modules/VideoSlider'

import styles from './style'

class Home extends Component {
  componentDidMount = () => {
    this.props.loadImportantNews()
    this.props.loadNews()
    this.props.loadNewsbanner()
    this.props.fetchPage('welcome')
  }

  handleSubmit = (value, filter) => {
    this.props.history.push('/search', { value, filter: filter.value })
  }

  render(): React$Node {
    const { importantTree: important, newsbanner, data } = this.props

    return (
      <div className={css(styles.main)}>
        <Helmet title="Startseite" />

        <div className="no-gap container">
          <div className="row">
            {typeof important.themaDerWoche === 'object' && (
              <div className="col-md-12">
                <Box
                  img={important.themaDerWoche.image}
                  isBig
                  item={important.themaDerWoche.link}
                  preline="THEMA DER WOCHE"
                  headline={important.themaDerWoche.title}
                  buttonText={important.themaDerWoche.buttonText}
                  text={important.themaDerWoche.beschreibung}
                  showLink={important.themaDerWoche.showLink}
                  dokumentStattBeitragVerlinken={
                    important.themaDerWoche.dokumentStattBeitragVerlinken
                  }
                  dokument={important.themaDerWoche.dokument}
                  globallink={important.themaDerWoche.globallink}
                  globalerLinkText={important.themaDerWoche.globalerLinkText}
                />
              </div>
            )}
            {Array.isArray(important.highlights) && (
              <div className="col-md-12">
                <div className={css(styles.boxHolder)}>
                  {important.highlights.map((item, index) => (
                    <Box
                      key={index.toString()}
                      color={item.color}
                      preline={item.preline}
                      item={item.link}
                      newlink={item.newLink}
                      buttonText={item.buttonText}
                      headline={item.titel}
                      text={item.subtitle}
                      countdown={item.countdown}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="col-xl-12 col-lg-12">{newsbanner && <Banner data={newsbanner} />}</div>
            <div className="col-xl-12 col-lg-12">
              <EventWeek />
            </div>
            <div className="col-xl-12 col-lg-12">
              <Linklist cipher={this.props.cipher} userLogin={this.props.userLogin} />
              {this.props.importantNews.length !== 0 && (
                <Linklist cipher={this.props.cipher} isNews data={this.props.importantNews} />
              )}
            </div>
            {data[0] && data[0].acf.dokumenteAnzeigen && (
              <div className="col-xl-12 col-lg-12">
                <Documents />
              </div>
            )}
            <div className="col-xl-8 col-md-12">
              <VideoSlider isHome />
            </div>
            <div className="col-xl-4 col-md-12">
              <Facebook />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default connect(
  state => ({
    data: selectPage(state),
    date: selectCurrentDate(state),
    events: selectEventsForDate(state),
    articleOfTheWeek: selectArticleOfTheWeek(state),
    highligtedArticle: selectHighligtedArticle(state),
    importantNews: selectImportantArticle(state),
    news: selectNewsData(state),
    newsbanner: selectNewsbanner(state),

    importantTree: selectImportantTree(state),
  }),
  { loadNews, fetchPage, loadNewsbanner, loadImportantNews }
)(Home)
