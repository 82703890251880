// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  bar: {
    marginTop: 40,
    marginBottom: 45,
  },
  mainHolder: {
    padding: '100px 0 55px',
  },
  textlink: {
    fontSize: 16,
    color: '#2771C6',
    lineHeight: '34px',
    display: 'block',
  },
  videoHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
})
