// @flow

import { LOAD_SEARCH_REQUEST, LOAD_SEARCH_SUCCESS, LOAD_SEARCH_FAILURE } from './actionTypes'

import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  data: [],
}

export const reducer = (state: State = initialState, { type, response }: Action): State => {
  switch (type) {
    case LOAD_SEARCH_REQUEST:
      return { ...state, loaded: false }

    case LOAD_SEARCH_SUCCESS:
      return { ...state, loaded: true, data: response }

    case LOAD_SEARCH_FAILURE:
      return initialState

    default:
      return state
  }
}
