// @flow
import { StyleSheet } from 'aphrodite/no-important'
import { rgba } from 'polished'
import { primary } from 'constants/color'

const paddingHorizontal: number = 25
const paddingVertical: number = 15

const itemStyle = {
  textAlign: 'left',
  lineHeight: 1,
  paddingLeft: paddingHorizontal,
  paddingRight: paddingHorizontal,
  paddingTop: paddingVertical,
  paddingBottom: paddingVertical,

  ':first-of-type': {
    textAlign: 'center',
  },

  ':last-of-type': {
    textAlign: 'right',
  },
}

export default StyleSheet.create({
  cart: {
    fontSize: 14,
  },

  cart__headline: {
    marginBottom: 30,
  },

  cart__table: {
    width: '100%',
  },

  cart__tr: {
    ':nth-child(even)': {
      background: rgba(primary, 0.05),
    },
  },

  cart__td: {
    ...itemStyle,
  },

  cart__th: {
    ...itemStyle,
  },
})
