// @flow

import { LOAD_MENU_REQUEST, LOAD_MENU_SUCCESS, LOAD_MENU_FAILURE } from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  data: [],
}

export const reducer = (state: State = initialState, { type, response }: Action): State => {
  switch (type) {
    case LOAD_MENU_REQUEST:
      return { ...state, loaded: false }

    case LOAD_MENU_SUCCESS:
      return { ...state, loaded: true, data: response }

    case LOAD_MENU_FAILURE:
      return initialState

    default:
      return state
  }
}
