import React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { opacify } from "polished";

export function Headline({ type, style, children }) {
  switch (type) {
    case "h1":
      return (
        <h1 className={css(styles.h1)} style={style}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={css(styles.h2)} style={style}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 className={css(styles.h3)} style={style}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 className={css(styles.h4)} style={style}>
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5 className={css(styles.h5)} style={style}>
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6 className={css(styles.h6)} style={style}>
          {children}
        </h6>
      );
    default:
      break;
  }
}

export function Text({ type, style, children }) {
  switch (type) {
    case "lead":
      return (
        <p className={css(styles.textLead)} style={style}>
          {children}
        </p>
      );
    case "small":
      return (
        <p className={css(styles.textSmall)} style={style}>
          {children}
        </p>
      );
    case "extraSmall":
      return (
        <p className={css(styles.textExtraSmall)} style={style}>
          {children}
        </p>
      );

    default:
      return (
        <p className={css(styles.textBasic)} style={style} dangerouslySetInnerHTML={{ __html: children}} />
      );
  }
}

const headlineColor = "#003366";
const textColor = "#212529";
const resetDefault = {
  opacity: 1,
  textTransform: "initial",
};

const styles = StyleSheet.create({
  h1: {
    ...resetDefault,
    fontSize: 32,
    lineHeight: "38px",
    color: headlineColor,
  },
  h2: {
    ...resetDefault,
    fontSize: 28,
    lineHeight: "34px",
    color: headlineColor,
  },
  h3: {
    ...resetDefault,
    fontSize: 25,
    lineHeight: "31px",
    color: headlineColor,
  },
  h4: {
    ...resetDefault,
    fontSize: 22,
    lineHeight: "26px",
    color: headlineColor,
  },
  h5: {
    ...resetDefault,
    fontSize: 20,
    lineHeight: "24px",
    color: headlineColor,
  },
  h6: {
    ...resetDefault,
    fontSize: 18,
    lineHeight: "24px",
    color: headlineColor,
  },
  textBasic: {
    ...resetDefault,
    fontSize: 16,
    lineHeight: "24px",
    color: textColor,
  },
  textLead: {
    ...resetDefault,
    fontSize: 18,
    lineHeight: "22px",
    color: textColor,
  },
  textSmall: {
    ...resetDefault,
    fontSize: 14,
    lineHeight: "24px",
    color: textColor,
  },
  textExtraSmall: {
    ...resetDefault,
    fontSize: 14,
    lineHeight: "20px",
    color: textColor,
  },
});
