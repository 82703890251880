export const dayNames = [
  'Sonntag',
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
]
export const monthNames = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]

export const convertTime = t => {
  const hour = t.split(':')[0]
  const min = t.split(':')[1].slice(0, 2)
  const ident = t.split(' ')[1]

  if (ident === 'am' || ident === 'AM') {
    if (hour.length === 1) return `0${hour}:${min}`
    return `${hour}:${min}`
  }
  if (ident === 'pm' || ident === 'PM') {
    const newHour = parseInt(hour) + 12
    return `${newHour}:${min}`
  }
}
