// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'
import { TABLET } from 'constants/breakpoints'

export default StyleSheet.create({
  main: {
    background: colors.white,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    pointerEvents: 'none',
    transform: 'translateX(20px)',
    transition: 'all 400ms ease-in-out',

    // opacity: 1,
    // pointerEvents: 'all',

    [TABLET]: {
      position: 'relative',
      width: 'calc(100% + 50px)',
      transform: 'translateX(0)',
      top: -25,
      left: 0,
      right: -25,
      // width: '100%',
      height: 70,
      background: 'white',
      borderBottom: '1px solid #eee',
      padding: 16,
    },
  },
  mainActive: {
    opacity: 1,
    pointerEvents: 'all',
    transform: 'translateX(0)',

    [TABLET]: {},
  },
  mainInput: {
    flex: 1,
    fontSize: 14,
    color: colors.darkFont,
    background: '#f6f8fa',
    borderRadius: '4px 0 0 4px',
    paddingTop: 0,
    paddingBottom: 0,
    height: 42,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    '-webkit-appearance': 'none',
  },
  caret: {
    marginLeft: 10,
    width: 8,
    height: 5,
  },
})
