// @flow

import { SELECT_VIDEO } from './actionTypes'
import type { State, Action } from './types'

const initialState: State = {
  index: '',
}

export const reducer = (state: State = initialState, { type, payload }: Action): State => {
  switch (type) {
    case SELECT_VIDEO:
      return { index: payload }

    default:
      return state
  }
}
