export default function checkUrl() {
  const base = window.location.host

  switch (base) {
    case '192.109.14.157':
      return 'https://192.109.14.157'

    case 'test-de-portal.myovb.com':
      return 'https://test-de-portal.myovb.com'

    case 'localhost:3005':
      return 'https://test-de-portal.myovb.com'

    case '10.233.177.127':
      return 'http://10.233.177.127'

    case 'staging-portal.ovb.de':
      return 'https://192.109.14.157'

    case 'abnahme-portal.ovb.de':
      return 'https://abnahme-portal.ovb.de'

    default:
      return 'https://portal.ovb.de'
  }
}
