// @flow
import { createSelector } from 'reselect'
import type { Selector } from 'reselect'
import { orderBy } from 'lodash'
import type { State as RootState } from '../types'
import type { State, Products } from './types'

export const selectProductsTree = (state: RootState): State => state.products

export const selectLoaded: Selector<*, *, boolean> = createSelector(
  selectProductsTree,
  tree => tree.loaded
)

export const selectProducts: Selector<*, *, Products> = createSelector(
  selectProductsTree,
  ({ data, orderByVal, orderDirection, filters }): React$Node => {
    const products = data

    // sort logic
    let sortedProducts
    if (orderByVal === 'title.rendered') {
      sortedProducts = orderBy(products, [(o): React$Node => o.title.rendered], [orderDirection])
    } else if (orderByVal === 'acf.preis') {
      sortedProducts = orderBy(products, [(o): React$Node => o.acf.preis], [orderDirection])
    } else {
      sortedProducts = orderBy(products, [(o): React$Node => o.title.rendered], [orderDirection])
    }

    // filter logic
    let filteredProducts
    if (filters.length !== 0) {
      filteredProducts = sortedProducts.filter(
        (o): React$Node =>
          parseInt(o.acf.preis, 10) > filters[0] && parseInt(o.acf.preis, 10) < filters[1]
      )

      return filteredProducts
    }

    return sortedProducts
  }
)

export const selectProductCategories: Selector<*, *, Products> = createSelector(
  selectProductsTree,
  tree => tree.categories
)
