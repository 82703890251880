// @flow
import type { Store } from 'redux'

import callApi, { CALL_API } from 'utils/callApi'
import type { APIAction } from 'utils/callApi.types'

export default (store: Store<*, *>) => (next: Function) => (action: APIAction): Promise<*> => {
  const callAPI = action[CALL_API]

  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint } = callAPI
  const { types, options = {}, external } = callAPI

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  function actionWith(data): * {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const [requestType, successType, failureType] = types
  const addition = options.addition || {}

  next(actionWith({ type: requestType }))

  return callApi(endpoint, options, external)
    .then(
      (response: Object | Array<Object>): Promise<*> =>
        next(actionWith({ response, type: successType, ...addition }))
    )
    .catch(
      (error): Promise<*> => {
        next(actionWith({ type: failureType, error, ...addition }))
        return Promise.reject(error)
      }
    )
}
