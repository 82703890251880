// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  items: {},

  items__headline: {
    margin: '40px 0',
  },
})
