// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { css, StyleSheet } from 'aphrodite/no-important'

import bereiche from 'constants/bereiche'
import Dropdown from 'components/Dropdown'
import { startSearch } from 'modules/search/actions'

import searchIcon from 'assets/search-white.png'

const styles = StyleSheet.create({
  search: {
    '-webkit-appearance': 'none',
    appearance: 'none',
    outline: 'none',
    boxShadow: 'none',
    background: '#fff',
    fontSize: 14,
    color: '#7f7f7f',
    padding: '12px 24px',
    height: '100%',
    width: '100%',
    paddingRight: 75,
    paddingTop: 14,
    paddingBottom: 14,
    border: '1px solid #eee',
  },
  searchHolder: {
    overflow: 'hidden',
    position: 'relative',
  },
  btn: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    background: '#003a65',
    cursor: 'pointer',
    transition: 'all 300ms ease',
    ':hover': {
      background: '#2771D9',
    },
  },
  btnImage: {
    width: 23,
    height: 23,
  },
})

// eslint-disable-next-line import/no-mutable-exports
class SearchBar extends React.Component {
  state = {
    dropDownValue: 'Alle Bereiche',
    extendedFilterValue: 'Sortieren',
    inputValue: '',
    useInitial: true,
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.inputValue !== nextProps.value) {
      this.setState({ inputValue: nextProps.value })
    }
  }

  onDropDownChange = e => {
    this.setState({ dropDownValue: e })

    this.props.onFilterChange && this.props.onFilterChange(e)
  }

  onExtendedFilterChange = e => {
    this.setState({ extendedFilterValue: e })

    this.props.onExtendedFilterChange && this.props.onExtendedFilterChange(e)
  }

  onInputChange = e => {
    const value = e.currentTarget.value

    this.props.onChange && this.props.onChange(value)

    this.setState({ inputValue: value, useInitial: false })
  }

  onSubmit = evt => {
    evt.preventDefault()

    this.props.onSubmit &&
      this.props.onSubmit(
        this.state.inputValue,
        this.state.dropDownValue,
        this.state.extendedFilterValue
      )
  }

  render(): React$Node {
    let columnWidth = 12
    let { inputValue } = this.state
    const { dropDownSize = 2 } = this.props
    const { useInitial } = this.state

    if (!inputValue && useInitial) {
      inputValue = this.props.initialValue
    }

    if (!this.props.hideDropDown) {
      columnWidth -= dropDownSize
    }
    if (this.props.showExtendedFilter) {
      columnWidth -= 2
    }

    return (
      <div className="row">
        {!this.props.hideDropDown && (
          <div className={`col-sm-${dropDownSize}`}>
            <Dropdown
              onChange={this.onDropDownChange}
              initialValue={this.props.initialFilterValue}
              placeholder={this.props.initialFilterValue}
              currentValue={this.state.dropDownValue}
              {...this.props.dropDown}
            />
          </div>
        )}
        {this.props.showExtendedFilter && (
          <div className="col-sm-2">
            <Dropdown
              placeholder="Sortieren"
              onChange={this.onExtendedFilterChange}
              currentValue={this.state.extendedFilterValue}
              values={[
                { value: 'postDate,ASC', label: 'Datum alte zuerst' },
                { value: 'postDate,DESC', label: 'Datum aktuellste zuerst' },
                { value: 'postTitle,ASC', label: 'Alphabetisch aufsteigend' },
                { value: 'postTitle,DESC', label: 'Alphabetisch absteigend' },
              ]}
            />
          </div>
        )}
        <div className={`col-sm-${columnWidth}`}>
          <form onSubmit={this.onSubmit} style={{ flex: '1 1 100%' }}>
            <div className={css(styles.searchHolder)}>
              <input
                onChange={this.onInputChange}
                name="search"
                value={inputValue}
                placeholder={this.props.placeholder}
                className={css(styles.search)}
              />

              <button type="submit" className={css(styles.btn)}>
                <img src={searchIcon} alt="" className={css(styles.btnImage)} />
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  { startSearch }
)(SearchBar)
