// @flow
export const LOAD_CONTACTS_REQUEST: string = 'LOAD_CONTACTS_REQUEST'
export const LOAD_CONTACTS_SUCCESS: string = 'LOAD_CONTACTS_SUCCESS'
export const LOAD_CONTACTS_FAILURE: string = 'LOAD_CONTACTS_FAILURE'

export const LOAD_SINGLECONTACT_REQUEST: string = 'LOAD_SINGLECONTACT_REQUEST'
export const LOAD_SINGLECONTACT_SUCCESS: string = 'LOAD_SINGLECONTACT_SUCCESS'
export const LOAD_SINGLECONTACT_FAILURE: string = 'LOAD_SINGLECONTACT_FAILURE'

export const SET_SORT: string = 'SET_SORT'
