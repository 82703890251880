// @flow
import React from 'react'
import Paginate from 'react-paginate'
import { css } from 'aphrodite/no-important'

import styles from './style'
import type { Props } from './types'

export default function Pagination(props: Props): React$Element<'div'> {
  return (
    <div className={css(styles.pagination)}>
      <div className={css(styles.pagination__perPage)}>
        Show:{' '}
        <div className={css(styles.pagination__selectContainer)}>
          <select
            name="perPage"
            onChange={props.onPerPageChange}
            className={css(styles.pagination__select)}
          >
            {['15', '30', '50'].map(index => (
              <option key={index} value={index}>
                {index}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Paginate
        pageCount={props.pageCount}
        pageRangeDisplayed={props.pageRangeDisplayed}
        marginPagesDisplayed={props.marginPagesDisplayed}
        breakLabel=""
        nextLabel="&rsaquo;"
        previousLabel="&lsaquo;"
        forcePage={props.currentPage - 1}
        onPageChange={props.onPageChange}
        containerClassName={css(styles.pagination__container)}
        previousClassName={css(styles.pagination__item)}
        nextClassName={css(styles.pagination__item)}
        pageClassName={css(styles.pagination__item)}
        activeClassName={css(styles['pagination__item--active'])}
      />
    </div>
  )
}

Pagination.defaultProps = {
  currentPage: 0,
  onPageChange: () => {},
}
