// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import check from 'assets/checkmark.png'

import styles from './style'

export default function Banner(): React$Element<'div'> {
  return (
    <div className={css(styles.banner)}>
      <img className={css(styles.bannerCheck)} src={check} alt="" />
      Danke für deinen Einkauf. Wir haben dir eine Email mit den nächsten Schritten zum Checkout
      gesendet.
    </div>
  )
}
