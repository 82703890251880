// @flow
import React, { Fragment, Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { css } from 'aphrodite/no-important'
import { createSelector } from 'reselect'

import includes from 'lodash/includes'
import sortBy from 'lodash/sortBy'

import 'react-accessible-accordion/dist/fancy-example.css'

import { Footer, Masthead } from 'components'

import PDFDownload from 'components/PDFDownload'
import unescape from 'utils/unescape'
import Spin from 'components/Spin'
import Dropdown from 'components/Dropdown'
import SideBarNavigation from 'components/Sidebar/Navigation'
import SearchBar from 'components/Form/SearchBar'

import { fetchPartners, fetchBranches } from 'modules/partners/actions'
import { selectLoaded, selectPartners, selectBranches } from 'modules/partners/selectors'

import logo from 'assets/barmer.png'

import Accordion from './components/Accordion'
import PartnerBox from './components/PartnerBox'

import styles from './style'

const emptyBranch: string = 'Sparte wählen...'
const emptyInsurance: string = 'Unternehmen wählen...'

class PartnerUebersicht extends Component<*, *> {
  state = {
    selectedPartner: emptyInsurance,
    selectedInsurance: false,
    selectedBranch: emptyBranch,
    urlParams: [],
  }

  async componentDidMount() {
    await this.props.fetchPartners()
    await this.props.fetchBranches()

    const { search: params } = window.location
    const search = params.substring(1)
    const urlParams = JSON.parse(
      `{"${decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    )

    this.setState(urlParams, () => {
      if (urlParams.branch) {
        this.handleBranchSelection(decodeURI(urlParams.branch))
      }

      if (urlParams.partner) {
        this.handlePartnerSelection(decodeURI(urlParams.partner))
      }
    })
  }

  handleBranchSelection = name => {
    const { branches } = this.props
    const filter = branches.filter(item => item.name === name)
    if (filter[0]) {
      const value = filter[0].id
      const label = filter[0].name

      const data = {}
      data.value = value
      data.label = label

      this.onBranchChange(data)
    }
  }

  handlePartnerSelection = name => {
    const { partners } = this.props
    const filter = partners.filter(item => item.title.rendered === name)
    if (filter[0]) {
      const value = filter[0].slug
      const label = filter[0].title.rendered

      const data = {}
      data.value = value
      data.label = label

      this.onDropDownChange(data)
    }
  }

  onChange = value => {
    this.setState({ selectedInsurance: value })
  }

  onDropDownChange = value => {
    this.setState({ selectedPartner: value })
  }

  onBranchChange = value => {
    this.setState({ selectedBranch: value })
  }

  filterPartners = (partner): boolean => {
    const { selectedPartner } = this.state
    if (selectedPartner !== emptyInsurance) return partner.slug === selectedPartner.value
    return true
  }

  filterInsurancesByInsurance = (insurance): boolean => {
    let { selectedInsurance } = this.state
    const partnerNames = insurance.partners.map(item => item.partnerName.toLowerCase())
    const partnerMails = insurance.partners.map(item => item.email.toLowerCase())
    const partnerKeywords = insurance.partners.map(item => item.keywords.toLowerCase())

    if (selectedInsurance) {
      selectedInsurance = selectedInsurance.toLowerCase()
      return (
        insurance.name.indexOf(selectedInsurance) !== -1 ||
        partnerNames.some(item => item.indexOf(selectedInsurance) !== -1) ||
        partnerKeywords.some(item => item.indexOf(selectedInsurance) !== -1) ||
        partnerMails.some(item => item.indexOf(selectedInsurance) !== -1)
      )
    }

    return true
  }

  filterInsurancesByBranch = (insurance): boolean => {
    const { selectedBranch } = this.state
    if (selectedBranch !== emptyBranch) return insurance.branch.termId === selectedBranch.value
    return true
  }

  sortPartners(partner): string { // eslint-disable-line
    const featuredPartners = [
      4038, // Signal Iduna
      4054, // Basler
      4113, // Generali
    ]

    if (includes(featuredPartners, partner.id)) {
      return `aaaaaaa${partner.slug}`
    }
    return partner.slug
  }

  renderPartners(): React$Node {
    let { partners } = this.props

    partners = sortBy(partners, this.sortPartners)

    partners = partners
      .filter(this.filterPartners)
      .map((partner): React$Node => {
        const insurances = partner.acf.insurances
          .filter(this.filterInsurancesByBranch)
          .filter(this.filterInsurancesByInsurance)

        return { ...partner, insurances }
      })
      .filter(partner => !!partner.insurances.length)

    if (!partners.length) {
      return (
        <div className={css(styles.emptyState)}>
          <img src={require('assets/empty-state.png')} alt="" style={{ maxWidth: 344 }} />

          <h3>Leider haben wir nichts zu Ihrer Suche gefunden.</h3>
          <p>Versuchen Sie es doch bitte mit einem anderen Suchbegriff.</p>
        </div>
      )
    }

    return partners.filter(this.filterPartners).map((partner): React$Node => {
      if (partner.insurances.length === 0) return null

      return (
        <PartnerBox
          key={unescape(partner.title.rendered)}
          logo={partner.betterFeaturedImage ? partner.betterFeaturedImage.sourceUrl : null}
          title={unescape(partner.title.rendered)}
          portalLink={partner.acf.portalLink}
          zweiterLink={partner.acf.zweiterLink}
          zweiterLinkText={partner.acf.zweiterLinkText}
        >
          {partner.insurances.map(insurance => (
            <Accordion title={insurance.name}>
              {insurance.partners &&
                insurance.partners.map(item => (
                  <div className={css(styles.ansprechpartner)}>
                    <h4>{unescape(item.partnerName)}</h4>
                    <table
                      cellSpacing={0}
                      cellPadding={0}
                      style={{ lineHeight: '2em', marginTop: 10 }}
                    >
                      {item.phoneNumber && (
                        <tr>
                          <td style={{ paddingRight: 20 }}>Telefon:</td>
                          <td>{item.phoneNumber}</td>
                        </tr>
                      )}
                      {item.faxNumber && (
                        <tr>
                          <td style={{ paddingRight: 20 }}>Telefax:</td>
                          <td>{item.faxNumber}</td>
                        </tr>
                      )}
                      {item.mobilfunknummer && (
                        <tr>
                          <td style={{ paddingRight: 20 }}>Mobil:</td>
                          <td>{item.mobilfunknummer}</td>
                        </tr>
                      )}
                      {item.email && (
                        <tr>
                          <td style={{ paddingRight: 20 }}>E-Mail:</td>
                          <td>{item.email}</td>
                        </tr>
                      )}
                    </table>
                  </div>
                ))}
            </Accordion>
          ))}
        </PartnerBox>
      )
    })
  }

  render(): React$Node {
    let { partners = [] } = this.props
    let { branches = [] } = this.props

    branches = branches.map(branch => ({
      value: branch.id,
      label: branch.name,
    }))

    if (partners) {
      partners = partners.sort((a, b) => {
        const nameA = unescape(a.title.rendered) && unescape(a.title.rendered.toLowerCase())
        const nameB = unescape(b.title.rendered) && unescape(b.title.rendered.toLowerCase())

        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      })
    }

    return (
      <div>
        <Helmet title="Unsere Partner" />
        <Masthead preline="Ihre OVB" headline="Unsere Partner" />
        <div className={css(styles.main)}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <h4 className={css(styles.headline)}>Seitenbereiche</h4>
                <SideBarNavigation />
              </div>
              <div className="col-lg-9 col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className={css(styles.mainHeadline)}>Unsere Partner</h2>
                    <p>
                      Zu unseren Partnern gehören namhafte Partner und internationale
                      Gesellschaften, die für langjährige Erfahrungen, Kundenorientierung und
                      Sicherheit stehen.
                    </p>
                    {this.props.erstePDFPartner && (
                      <PDFDownload
                        link={this.props.erstePDFPartner.url}
                        style={{ marginTop: 25, marginBottom: 25 }}
                      >
                        {this.props.erstePDFPartner.title}
                      </PDFDownload>
                    )}
                    {this.props.zweitePDFPartner && (
                      <PDFDownload
                        link={this.props.zweitePDFPartner.url}
                        style={{ marginTop: 25, marginBottom: 25 }}
                      >
                        {this.props.zweitePDFPartner.title}
                      </PDFDownload>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <Dropdown
                      placeholder={emptyBranch}
                      currentValue={this.state.selectedBranch}
                      onChange={this.onBranchChange}
                      values={branches}
                    />
                  </div>
                  <div className="col-sm-9">
                    <SearchBar
                      onChange={this.onChange}
                      onFilterChange={this.onDropDownChange}
                      dropDown={{
                        initialValue: emptyInsurance,
                        currentValue: this.state.selectedPartner,
                        placeholder: emptyInsurance,
                        values: partners.map(partner => ({
                          value: partner.slug,
                          label: unescape(partner.title.rendered),
                        })),
                      }}
                      placeholder="Suchen Sie nach einem Ansprechpartner..."
                      dropDownSize={4}
                    />
                  </div>
                </div>

                {this.props.loaded ? (
                  this.renderPartners()
                ) : (
                  <div style={{ padding: '40px 0' }}>
                    <Spin x2 />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = createSelector(
  selectLoaded,
  selectPartners,
  selectBranches,
  (loaded, partners, branches) => ({
    loaded,
    partners,
    branches,
  })
)

const mapActionsToProps = { fetchPartners, fetchBranches }

export default connect(mapStateToProps, mapActionsToProps)(PartnerUebersicht)
