// @flow

import { CALL_API } from 'utils/callApi'

import { LOAD_PAGE_REQUEST, LOAD_PAGE_SUCCESS, LOAD_PAGE_FAILURE } from './actionTypes'

export function fetchPage(slug: string): Object {
  return {
    [CALL_API]: {
      endpoint: `/wp/v2/pages?slug=${slug}`,
      types: [LOAD_PAGE_REQUEST, LOAD_PAGE_SUCCESS, LOAD_PAGE_FAILURE],
    },
  }
}

export function clearPage(): any {
  return {
    type: "CLEAR_PAGE",
    response: "CLEAR_PAGE",
  }
}
