// @flow
import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite/no-important'
import Tooltip from '../Tooltip'

const styles = StyleSheet.create({
  wrapper: {
    cursor: 'pointer',
    position: 'relative',
    width: 16,
    height: 16,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default class PlusIcon extends Component {
  state = { active: false }

  render() {
    const { color, bg, onClick } = this.props

    return (
      <div
        onMouseEnter={() => this.setState({ active: true })}
        onMouseLeave={() => this.setState({ active: false })}
        className={css(styles.wrapper)}
        style={{ backgroundColor: bg }}
        onClick={onClick}
      >
        <Tooltip title="Add to calendar" active={this.state.active} />
        <svg fill="none" height="8" viewBox="0 0 8 8" width="8" xmlns="http://www.w3.org/2000/svg">
          <g
            stroke={color || '#003a65'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          >
            <path d="m4 1v6.00039" />
            <path d="m6.99844 4h-6.000393" />
          </g>
        </svg>
      </div>
    )
  }
}
