// @flow

import {
  LOAD_PARTNERS_REQUEST,
  LOAD_PARTNERS_SUCCESS,
  LOAD_PARTNERS_FAILURE,
  LOAD_BRANCHES_SUCCESS,
} from './actionTypes'

import type { State, Action } from './types'

const initialState: State = {
  loaded: false,
  data: [],
  branches: [],
}

export const reducer = (state: State = initialState, { type, response }: Action): State => {
  switch (type) {
    case LOAD_PARTNERS_REQUEST:
      return { ...state, loaded: false }

    case LOAD_PARTNERS_SUCCESS:
      return { ...state, loaded: true, data: response }

    case LOAD_PARTNERS_FAILURE:
      return initialState

    case LOAD_BRANCHES_SUCCESS:
      return { ...state, branches: response }

    default:
      return state
  }
}
