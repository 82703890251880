// @flow
import { StyleSheet } from 'aphrodite/no-important'

import { colors } from 'constants'
import { TABLET } from 'constants/breakpoints'

export default StyleSheet.create({
  nav: {
    [TABLET]: {
      border: '1px solid #D5DEE4',
    },
  },
  headline: {
    cursor: 'pointer',
    padding: '35px 0',
    [TABLET]: {
      transition: 'all 300ms ease',
      padding: '20px 24px',
    },
  },
  headlineActive: {
    [TABLET]: {
      background: colors.primary,
      color: colors.white,
    },
  },
  navItem: {
    fontSize: 16,
    color: '#0073ca',
    lineHeight: '32px',
    display: 'block',
    cursor: 'pointer',
  },
  navItemActive: {
    fontWeight: 500,
  },
  content: {
    [TABLET]: {
      padding: '0 24px',
      maxHeight: 0,
      overflow: 'hidden',
    },
  },
  contentActive: {
    [TABLET]: {
      padding: '10px 24px',
      maxHeight: 'none',
    },
  },
})
