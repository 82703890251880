// @flow
import React from 'react'

export default function ZIP({ className }) {
  return (
    <svg className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#013a65"
        d="M458 118L347 8l-6-2H135c-31 0-56 27-56 59v220H51v148h28v14c0 32 25 59 56 59h269c31 0 57-27 57-59V125c0-3-1-5-3-7zM338 24l97 96h-53c-24 0-44-20-44-43V24zm106 423c0 23-18 41-40 41H135c-22 0-39-18-39-41v-14h187c36 0 66-33 66-74s-30-74-66-74H96V65c0-22 17-41 39-41h186v53c0 33 27 61 61 61h62v309zM122 332v-10h51v7l-37 58h38v10h-52v-8l36-57h-36zm69 64v-75h12v75h-12zm32 0v-75h29c8 0 15 2 19 7s7 10 7 17-2 12-7 17c-4 4-11 6-19 6h-16v28h-13z"
      />
      <path
        fill="#013a65"
        d="M262 354c3-2 4-5 4-9s-1-7-4-10c-2-2-5-3-10-3h-16v26h16c5 0 8-1 10-4z"
      />
    </svg>
  )
}
