// @flow
import { StyleSheet } from 'aphrodite/no-important'

export default StyleSheet.create({
  main: {
    marginTop: 75,
  },
  videoHolder: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  alignRight: {
    display: 'flex',
    width: '100%',
    padding: '14px 0',
    justifyContent: 'flex-end',
  },
})
