// @flow

import { CALL_API } from 'utils/callApi'
import {
  LOAD_VIDEOS_REQUEST,
  LOAD_VIDEOS_SUCCESS,
  LOAD_VIDEOS_FAILURE,
  SET_SORT,
} from './actionTypes'

export function loadVideos(): * {
  return {
    [CALL_API]: {
      endpoint: '/wp/v2/videos?per_page=99',
      types: [LOAD_VIDEOS_REQUEST, LOAD_VIDEOS_SUCCESS, LOAD_VIDEOS_FAILURE],
    },
  }
}

export function loadFeaturedVideos(): * {
  return {
    [CALL_API]: {
      endpoint: '/wp/v2/videos?video_kategorien=209&per_page=3',
      types: [LOAD_VIDEOS_REQUEST, LOAD_VIDEOS_SUCCESS, LOAD_VIDEOS_FAILURE],
    },
  }
}

export function setSort(val: string): * {
  return {
    type: SET_SORT,
    response: val,
  }
}
