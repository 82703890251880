// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'

export default function Spin({ x2, color, ...props }: Object): React$Node {
  return (
    <div
      {...props}
      className={css(
        styles.ring,
        color === 'white' && styles['ring--white'],
        x2 && styles['ring--x2']
      )}
    >
      <span
        className={css(
          styles.ring__inner,
          color === 'white' && styles['ring__inner--white'],
          x2 && styles['ring__inner--x2']
        )}
      />
      <span
        className={css(
          styles.ring__inner,
          color === 'white' && styles['ring__inner--white'],
          x2 && styles['ring__inner--x2']
        )}
      />
    </div>
  )
}
