// @flow
import React from 'react'
import { NavLink } from 'react-router-dom'
import { css } from 'aphrodite/no-important'

// import type { Props, NavigationState } from './types'

import styles from './style'

export default function Navigation(): React$Element<'div'> {
  return (
    <div className={css(styles.main)}>
      <div className={css(styles.nav)}>
        <NavLink
          className={css(styles.navItem)}
          exact
          activeClassName={css(styles.navItemActive)}
          to="/ansprechpartner"
        >
          Ansprechpartner
        </NavLink>
        <NavLink
          className={css(styles.navItem)}
          activeClassName={css(styles.navItemActive)}
          to="/ansprechpartner/partner"
        >
          Unsere Partner
        </NavLink>
      </div>
    </div>
  )
}
