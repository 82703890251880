// @flow
import React from 'react'
import Helmet from 'react-helmet'
import { css } from 'aphrodite/no-important'
import { parse } from 'qs'

import { Masthead, EventsTable } from 'components'
import EventCalendar from 'components/_modules/EventCalendar'
import SearchBar from 'components/Form/SearchBar'
import Overflow from 'components/Overflow'
import Footer from 'components/Footer'

import styles from './style'

export default class Events extends React.Component {
  state = { filter: '' }

  componentDidMount() {
    this.checkForExitingSearch()
  }

  checkForExitingSearch = () => {
    const query = parse(this.props.location.search, { ignoreQueryPrefix: true })

    if (query.search) {
      this.setState({ filter: query.search })
    }
  }

  onChange = e => {
    this.setState({ filter: e })
  }

  render(): React$Node {
    return (
      <div className={css(styles.main)}>
        <Helmet>
          <title>Events</title>
          <meta name="description" content="OVB - Events" />
          <script src="https://cdn.rawgit.com/nwcell/ics.js/master/ics.deps.min.js" />
        </Helmet>
        <Masthead headline="Eventkalender" />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <EventCalendar isDetailPage isMasthead />
            </div>

            <div className={css(styles.actionHolder)}>
              <div className="col-md-12">
                <SearchBar hideDropDown onChange={this.onChange} value={this.state.filter} />
              </div>
            </div>

            <div className="col-md-12">
              <Overflow width={1000}>
                <EventsTable headline="Alle Events" filter={this.state.filter} />
              </Overflow>
              {/* <div className={css(styles.alignRight)}>
                <Pagination />
              </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
