// @flow
import React from 'react'
import { css } from 'aphrodite/no-important'

import styles from './style'

const Basket = () => (
  <div className={css(styles.main)}>
    <div className={css(styles.mainTop)}>
      <h4 className={css(styles.mainHeadline)}>Warenkorb (4)</h4>
      <div className={css(styles.mainMore)}>Ansehen</div>
    </div>
    <div className={css(styles.mainBox)}>Alu-Wandrahmen mit Klemmprofil A1 hinzugefügt</div>
  </div>
)

export default Basket
